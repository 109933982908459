import EmailPreviewModal from "components/EmailPreviewModal";
import {EmailData} from "components/EmailPreviewModal/EmailPreviewModal";
import useSendFollowUp from "hooks/useSendFollowUp";
import {useRef} from "react";
import {NewFollowUpModal} from "views/ProcessDetails/components/ProcessFollowUps/components";
import ProcessFollowUpData from "views/ProcessDetails/components/ProcessFollowUps/ProcessFollowUpData";
import {PostFollowUpType} from "views/ProcessDetails/components/ProcessFollowUps/ProcessFollowUpTypes";

type Props = {
  processId: number;
  processNumber: string;
  idGroupActivity: number;
  onCloseModal: () => void;
  modalIsOpen: boolean;
};

const CreateAndSendFollow = (props: Props) => {
  const {processId, processNumber, idGroupActivity, onCloseModal, modalIsOpen} =
    props;
  const formRefModalFollow = useRef<HTMLFormElement>(null);
  const {
    isOpen: previewEmailIsOpen,
    emailReturn,
    handleGetFollowUp,
    handleSendFollowUp,
    emailData,
    setEmailData,
    handleCloseModal,
  } = useSendFollowUp();

  const handleSendNewFollow = (
    formRefFollow: React.RefObject<HTMLFormElement>,
    localEmailData: EmailData,
    editedEmail: string,
  ) => {
    if (formRefFollow.current?.reportValidity()) {
      handleSendFollowUp(localEmailData, editedEmail);
    }
  };
  const {postFollowUp} = ProcessFollowUpData();

  const handlePostNewFollowUp = async (followUpData: PostFollowUpType) => {
    await postFollowUp(followUpData);
  };

  const handleCreateAndSendFollow = async () => {
    await handleGetFollowUp(+processId);
  };

  return (
    <>
      <NewFollowUpModal
        isOpen={modalIsOpen}
        processNumber={processNumber}
        idGroupActivity={idGroupActivity}
        onCloseModal={onCloseModal}
        onCreateAndSendFollow={handleCreateAndSendFollow}
        handlePostNewFollowUp={handlePostNewFollowUp}
        setModalEdit={() => void 0}
        handleUpdateFollowUp={() => void 0}
      />

      {previewEmailIsOpen && (
        <EmailPreviewModal
          processId={Number(processId)}
          isOpen={previewEmailIsOpen}
          handleClose={handleCloseModal}
          handleSendEmail={handleSendNewFollow}
          emailData={emailData}
          emailReturn={emailReturn}
          setEmailData={setEmailData}
          formRef={formRefModalFollow}
        />
      )}
    </>
  );
};

export default CreateAndSendFollow;
