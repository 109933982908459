import useAlertMessage from "hooks/useAlertMessage";
import {useReturnDebounce} from "hooks/useReturnDebounce";
import {useEffect, useRef, useState} from "react";
import {GetMessageModels} from "services/api_v2/MessageModels/MessageModels.service";

export type MessageModelFollowUpModal = {
  id: number;
  title: string;
  content: string;
};

const useFollowUpModal = () => {
  const [filteredMessageModels, setFilteredMessageModels] = useState<
    MessageModelFollowUpModal[]
  >([]);
  const [selectedListItem, setSelectedListItem] =
    useState<MessageModelFollowUpModal>();
  const [showSidebar, setShowSidebar] = useState(false);
  const [editorInputStack, setEditorInputStack] = useState<string[]>([]);
  const [initialModels, setInitialModels] = useState<
    MessageModelFollowUpModal[]
  >([]);

  const [showAlert] = useAlertMessage();

  const editorRef = useRef<any>();
  const searchRef = useRef<any>();

  const getModels = async () => {
    try {
      const response = await GetMessageModels({page: 1, limit: 100});

      const initialData = response.data.data.map((item) => ({
        id: item.id,
        title: item.name,
        content: item.content,
      }));

      setInitialModels(initialData);

      setFilteredMessageModels(initialData);
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
    }
  };

  const handleSearch = (input: string) => {
    if (input === "") {
      setFilteredMessageModels(initialModels);
      return;
    }

    const filtered = initialModels.filter((item) =>
      item?.title?.toLowerCase().includes(input?.toLowerCase()),
    );
    setFilteredMessageModels(filtered);
  };

  const text = editorRef?.current?.editingArea?.innerText;
  const debouncedText = useReturnDebounce(text, 2000);
  useEffect(() => {
    if (debouncedText?.trim()?.slice(-1) === "/") {
      setShowSidebar(true);
      searchRef?.current?.focus();
    }
  }, [debouncedText]);

  const handleKeyDownOnTextEditor = (delta: any) => {
    const insertedInEditor: {insert: string} = delta?.ops?.find((item) =>
      item?.hasOwnProperty("insert"),
    );
    setEditorInputStack((prev) => [...prev, insertedInEditor?.insert]);
  };

  const handleCloseSideBar = () => {
    setShowSidebar(false);
    setFilteredMessageModels(initialModels);
  };
  useEffect(() => {
    if (showSidebar) {
      searchRef?.current?.focus();
      setFilteredMessageModels(initialModels);
      setSelectedIndex(0);
      setSelectedListItem(initialModels[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSidebar]);

  const removeLastCharFromEditor = () => {
    const lastValueOfStack = editorInputStack[editorInputStack.length - 1];
    if (lastValueOfStack === "/") {
      const editor = editorRef.current.getEditor();
      const length = editor.getLength();

      if (length > 1) {
        editor.deleteText(length - 2, 1);
      }
    }
  };

  const [selectedIndex, setSelectedIndex] = useState(0);
  const itemRefs = useRef<(HTMLLIElement | null)[]>([]);

  useEffect(() => {
    if (itemRefs.current[selectedIndex]) {
      itemRefs.current[selectedIndex]?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
  }, [selectedIndex]);

  const handleKeyDownOnSearchInput = (
    e: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (!e) return;
    if (e.key === "ArrowDown") {
      e.preventDefault();

      const listLength = filteredMessageModels.length;

      const nextPosition =
        filteredMessageModels.findIndex(
          (item) => item.id === selectedListItem?.id,
        ) + 1;

      const listPosition =
        nextPosition >= listLength ? nextPosition - 1 : nextPosition;

      setSelectedListItem(filteredMessageModels[listPosition]);

      setSelectedIndex((prev) =>
        Math.min(prev + 1, filteredMessageModels.length - 1),
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();

      const nextPosition =
        filteredMessageModels.findIndex(
          (item) => item.id === selectedListItem?.id,
        ) - 1;

      const listPosition = nextPosition < 0 ? 0 : nextPosition;

      setSelectedListItem(filteredMessageModels[listPosition]);

      setSelectedIndex((prev) => Math.max(prev - 1, 0));
    } else if (e.key === "Enter") {
      e.preventDefault();
      editorRef?.current?.focus();

      handleCloseSideBar();

      removeLastCharFromEditor();

      document.execCommand(
        "insertHTML",
        false,
        filteredMessageModels.find((item) => item.id === selectedListItem?.id)
          ?.content,
      );
    } else if (e.key === "Escape") {
      e.preventDefault();
      handleCloseSideBar();

      editorRef?.current?.focus();
    }
  };

  const handleOnKeyDownEditor = (key: string) => {
    if (key === "Escape") {
      handleCloseSideBar();
    }
  };

  const handleClickOnListItem = (item: MessageModelFollowUpModal) => {
    editorRef?.current?.focus();
    removeLastCharFromEditor();
    document.execCommand("insertHTML", false, item.content);
    handleCloseSideBar();
  };

  useEffect(() => {
    (async () => await getModels())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    messageModels: filteredMessageModels,
    handleSearch,
    selectedListItem,
    handleKeyDownOnSearchInput,
    searchRef,
    handleKeyDownOnTextEditor,
    showSidebar,
    handleClickOnListItem,
    editorRef,
    itemRefs,
    handleOnKeyDownEditor,
    setShowSidebar,
  };
};

export default useFollowUpModal;
