/**
 * Pega um html e extrai apenas o texto.
 * Se tiver uma quebra de linha com </br> será substituído para espaço.
 */
export const extractTextFromHtmlString = (htmlString: string) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString.replace(/<br\s*\/?>/g, " ");

  return tempDiv.textContent || tempDiv.innerText || "";
};
