import "react-datepicker/dist/react-datepicker.css";

import {Fragment, useEffect, useState} from "react";
import DatePicker, {ReactDatePickerProps} from "react-datepicker";
import {ptBR} from "date-fns/locale";
import {format} from "date-fns";
import {Typography, useTheme} from "@mui/material";
import {Grid} from "@mui/system";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import pxToRem from "hooks/usePxToRem";
import DatePickerWrapper from "./DatePickerWrapper";
import CustomInput from "ui/CustomInput";
import {SplitInput} from "./components";
import {CustomButton} from "ui";
import {brazilianTimeZoneCurrentDate, timeZone} from "global/constants";
import {utcToZonedTime} from "date-fns-tz";

export type DatePickerValues = {
  startDate: Date | null;
  endDate: Date | null;
  startTime: string | null;
  endTime: string | null;
};

interface Props extends Partial<ReactDatePickerProps> {
  variant?: "default" | "dateAndTime" | "range" | "rangeAndTime";
  customInput?: React.ReactNode;
  showFooter?: boolean;
  customFooter?: React.ReactNode;
  showDateHeader?: boolean;
  getDatePickerValuesOnClose?: (values: DatePickerValues) => void;
  preDefinedRange?: Date | [Date | null, Date | null];
  onDatesConfirm?: (values: DatePickerValues) => void;
  fullWidth?: boolean;
}

const CustomDatePicker = (props: Props) => {
  const {
    variant = "default",
    customInput,
    getDatePickerValuesOnClose = () => void 0,
    showFooter = false,
    customFooter,
    showDateHeader = true,
    preDefinedRange = [null, null],
    onDatesConfirm = () => void 0,
    dateFormat = "dd/MM/yyyy",
    placeholderText = "DD/MM/YYYY",
    fullWidth = false,
    ...otherProps
  } = props;

  const isRangePicker = variant === "range" || variant === "rangeAndTime";

  const [startDate, setStartDate] = useState<Date | null>(null);

  const [endDate, setEndDate] = useState<Date | null>(null);

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const onChange = (dates: Date | [Date | null, Date | null]) => {
    if (isRangePicker) {
      const [start, end] = dates as Array<Date>;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(dates as Date);
    }
  };

  const onCalendarClose = () => {
    getDatePickerValuesOnClose({startTime, endTime, startDate, endDate});
  };

  const handleConfirmDates = () => {
    onDatesConfirm({startTime, endTime, startDate, endDate});
  };

  const handleSetToday = () => {
    startDate === null
      ? setStartDate(brazilianTimeZoneCurrentDate)
      : setEndDate(brazilianTimeZoneCurrentDate);
    if (startDate === null && endDate === null) {
      setStartDate(brazilianTimeZoneCurrentDate);
    } else if (startDate === null && endDate !== null) {
      setStartDate(brazilianTimeZoneCurrentDate);
    } else if (startDate !== null && endDate === null) {
      setEndDate(brazilianTimeZoneCurrentDate);
    } else if (startDate !== null && endDate !== null) {
      setStartDate(brazilianTimeZoneCurrentDate);
      setEndDate(null);
    }
  };

  const theme = useTheme();

  useEffect(() => {
    if (preDefinedRange[0] !== null) {
      onChange(preDefinedRange);
    }
  }, [preDefinedRange]);

  return (
    <DatePickerWrapper fullWidth={fullWidth}>
      <DatePicker
        {...otherProps}
        selected={startDate}
        onChange={onChange}
        onCalendarClose={onCalendarClose}
        startDate={startDate}
        dateFormat={dateFormat}
        placeholderText={placeholderText}
        disabledKeyboardNavigation
        endDate={endDate}
        showPopperArrow={false}
        selectsRange={isRangePicker}
        locale={ptBR}
        shouldCloseOnSelect={true}
        customInput={customInput}
        renderCustomHeader={({date, decreaseMonth, increaseMonth}) => (
          <Grid container width={pxToRem(248)} rowGap={pxToRem(8)}>
            {showDateHeader && (
              <Fragment>
                {variant === "default" && (
                  <Grid
                    container
                    size={12}
                    width={pxToRem(120)}
                    spacing={1}
                    p={`0 ${pxToRem(12)}`}
                  >
                    <Grid size={12}>
                      <CustomInput
                        sx={{
                          height: pxToRem(28),
                          fontSize: pxToRem(12),
                        }}
                        size="small"
                        customFocus={
                          startDate === null
                            ? theme.palette.primary[600]
                            : undefined
                        }
                        value={
                          startDate === null
                            ? ""
                            : format(
                                utcToZonedTime(startDate, timeZone),
                                "dd/MM/yyyy",
                              )
                        }
                        placeholder="Data"
                      />
                    </Grid>
                  </Grid>
                )}
                {variant === "dateAndTime" && (
                  <SplitInput
                    sx={{
                      height: pxToRem(28),
                      fontSize: pxToRem(12),
                    }}
                    widthInPxEachInput={pxToRem(112)}
                    firsInputValue={
                      startDate === null
                        ? ""
                        : format(startDate, "dd/MM/yyyy", {
                            locale: ptBR,
                          })
                    }
                    customFocus={
                      startDate === null
                        ? theme.palette.primary[600]
                        : undefined
                    }
                    value={startTime}
                    onChange={(value: string) => setStartTime(value)}
                  />
                )}

                {variant === "range" && (
                  <Grid
                    container
                    size={12}
                    width={pxToRem(120)}
                    spacing={1}
                    p={`0 ${pxToRem(12)}`}
                  >
                    <Grid size={6}>
                      <CustomInput
                        sx={{
                          height: pxToRem(28),
                          fontSize: pxToRem(12),
                        }}
                        customFocus={
                          startDate === null
                            ? theme.palette.primary[600]
                            : undefined
                        }
                        value={
                          startDate === null
                            ? ""
                            : format(
                                utcToZonedTime(startDate, timeZone),
                                "dd/MM/yyyy",
                              )
                        }
                        placeholder="Início"
                      />
                    </Grid>
                    <Grid size={6}>
                      <CustomInput
                        sx={{height: pxToRem(28), fontSize: pxToRem(12)}}
                        customFocus={
                          endDate === null && startDate !== null
                            ? theme.palette.primary[600]
                            : undefined
                        }
                        value={
                          endDate !== null
                            ? format(
                                utcToZonedTime(endDate, timeZone),
                                "dd/MM/yyyy",
                              )
                            : ""
                        }
                        placeholder="Fim"
                      />
                    </Grid>
                  </Grid>
                )}

                {variant === "rangeAndTime" && (
                  <Fragment>
                    <SplitInput
                      sx={{
                        height: pxToRem(28),
                        fontSize: pxToRem(12),
                      }}
                      widthInPxEachInput={pxToRem(112)}
                      firsInputValue={
                        startDate === null
                          ? ""
                          : format(startDate, "dd/MM/yyyy", {
                              locale: ptBR,
                            })
                      }
                      customFocus={
                        startDate === null
                          ? theme.palette.primary[600]
                          : undefined
                      }
                      value={startTime}
                      onChange={(value: string) => setStartTime(value)}
                    />
                    <SplitInput
                      sx={{
                        height: pxToRem(28),
                        fontSize: pxToRem(12),
                      }}
                      widthInPxEachInput={pxToRem(112)}
                      firsInputValue={
                        endDate !== null
                          ? format(endDate, "dd/MM/yyyy", {locale: ptBR})
                          : ""
                      }
                      customFocus={
                        endDate === null && startDate !== null
                          ? theme.palette.primary[600]
                          : undefined
                      }
                      value={endTime}
                      onChange={(value: string) => setEndTime(value)}
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
            <Grid
              container
              size={12}
              width={pxToRem(150)}
              p={`${pxToRem(8)} ${pxToRem(12)}}`}
            >
              <Grid
                onClick={decreaseMonth}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  cursor: "pointer",
                  width: pxToRem(20),
                  height: pxToRem(20),
                  borderRadius: pxToRem(4),
                  ":hover": {
                    boxShadow: `0 0 ${pxToRem(4)} rgba(0, 0, 0, 0.1)`,
                  },
                }}
              >
                <ArrowBackIosNewIcon sx={{fontSize: pxToRem(14)}} />
              </Grid>
              <Grid flexGrow={1}>
                <Typography variant="label1" textTransform="capitalize">
                  {format(date, "LLLL yyyy", {locale: ptBR})}
                </Typography>
              </Grid>
              <Grid
                onClick={increaseMonth}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  cursor: "pointer",
                  width: pxToRem(20),
                  height: pxToRem(20),
                  borderRadius: pxToRem(4),
                  ":hover": {
                    boxShadow: `0 0 ${pxToRem(4)} rgba(0, 0, 0, 0.1)`,
                  },
                }}
              >
                <ArrowForwardIosIcon sx={{fontSize: pxToRem(14)}} />
              </Grid>
            </Grid>
          </Grid>
        )}
      >
        {customFooter}
        {showFooter && !customFooter && (
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            p={pxToRem(15)}
          >
            <Grid size={6} display="flex" justifyContent="flex-start">
              <CustomButton
                variant="outlined"
                size="small"
                secondary
                onClickFn={handleSetToday}
                sx={{height: pxToRem(30)}}
              >
                Hoje
              </CustomButton>
            </Grid>

            <Grid size={6} display="flex" justifyContent="flex-end">
              <CustomButton
                variant="outlined"
                size="small"
                secondary
                onClickFn={handleConfirmDates}
                sx={{height: pxToRem(30)}}
              >
                Confirmar
              </CustomButton>
            </Grid>
          </Grid>
        )}
      </DatePicker>
    </DatePickerWrapper>
  );
};

export default CustomDatePicker;
