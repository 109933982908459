import {useEffect, useState} from "react";
import pxToRem from "hooks/usePxToRem";

import {Stack, Typography} from "@mui/material";

import {CustomButton} from "ui";
import {ConfirmModal, DefaultCardStructure} from "components";
import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {
  DeleteLogisticBillLadingLoad,
  PatchProcessById,
} from "services/api_v2/Processes/Processes.service";
import useAlertMessage from "hooks/useAlertMessage";
import useToast from "hooks/useToast";
import {DefaultProcessDetails} from "views/ProcessDetails/Process.types";
import {calcConsideredWeight} from "views/ProcessDetails/components/ProcessLoad/utils/calculateConsideredWeight";
import {CargoItem} from "./components";
import {useParams} from "react-router";
import {CloneCargoItemsOptions, CloneItem} from "types/ComponentsTypes";

type Props = {
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
};

const ActivityCargoItems = (props: Props) => {
  const {refetchProcess} = props;
  const {setValue, getValues} = useFormContext();
  const [showToast] = useToast();
  const [showAlert] = useAlertMessage();
  const loadIndex = 0;
  const {id: processId} = useParams<{id: string}>();

  const equipments = useWatch({name: "logisticEquipments"});

  const {fields, append, remove, update} = useFieldArray({
    name: `billLadingLoads`,
    keyName: "idForm",
  });

  const billLadingLoads = useWatch({name: `billLadingLoads`});

  const totalOfEquipments = () => {
    const total =
      equipments?.reduce((acc, curr) => acc + curr?.quantity, 0) ?? 0;
    return total;
  };

  const initialCargoItem = {
    number: "",
    seal: "",
    equipment: null,
    quantity: 0,
    netWeight: 0,
    grossWeight: 0,
    measurement: 0,
    tare: 0,
    typeItemLoad: "",
    barSeal: "",
    typePackages: null,
    packageContainer: null,
    dangerousGoodsClass: "",
    dangerousGoodsCode: "",
  };

  const handleAppendNewItem = () => {
    const isDirectMaster = getValues("isDirectMaster");
    const house = getValues("houseBL");

    if (!house && !isDirectMaster) {
      showToast("Não existe um house para o processo!", "error");
      return;
    }

    if (getValues(`processLoads[${loadIndex}].typeLoad`) === "LCL") {
      append(initialCargoItem);

      return;
    }

    if (!isDirectMaster && Object.keys(getValues("houseBL")[0])?.length === 0) {
      showToast("Não existe um house para o processo!", "error");
      return;
    }

    if (getValues("billLadingLoads")?.length >= totalOfEquipments()) {
      showToast(
        "O número de equipamentos é igual ou maior ao número de itens já existente",
        "warning",
      );
    } else {
      append(initialCargoItem);
    }
  };

  const setHouseMeasurements = (shouldDirtyFields = true) => {
    const cargoItems = getValues("billLadingLoads");
    const totalGrossWeight = cargoItems.reduce(
      (acc, prev) => acc + prev.grossWeight,
      0,
    );
    setValue(`processLoads[${loadIndex}].grossWeight`, totalGrossWeight, {
      shouldDirty: shouldDirtyFields,
    });
    const totalMeasurements = cargoItems.reduce(
      (acc, prev) => acc + prev.measurement,
      0,
    );
    setValue(`processLoads[${loadIndex}].cubicMeters`, totalMeasurements, {
      shouldDirty: shouldDirtyFields,
    });
    const totalQuantities = cargoItems.reduce(
      (acc, prev) => acc + prev.quantity,
      0,
    );
    setValue(`processLoads[${loadIndex}].quantities`, totalQuantities, {
      shouldDirty: shouldDirtyFields,
    });

    const loadData = calcConsideredWeight(
      totalMeasurements ?? 0,
      totalGrossWeight ?? 0,
    );
    setValue(
      `processLoads[${loadIndex}].consideredWeight`,
      loadData.consideredWeight,
      {shouldDirty: shouldDirtyFields},
    );
    setValue(`processLoads[${loadIndex}].cubeWeight`, loadData.cubeWeight, {
      shouldDirty: shouldDirtyFields,
    });
  };

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [itemToDelete] = useState<{
    itemId?: number;
    index?: number;
  }>({});

  /**
   * Não vamos mais deletar itens de carga, uma vez que já trazemos a quantidade
   * correta conforme quantidade de equipamentos. O botão de apagar foi substituído
   * por um botão para limpar os campos. Na tela de processos permanece possível a deleção
   */
  const handleDeleteLogisticBillLading = async (field: any, index: number) => {
    update(index, {id: field?.id, ...initialCargoItem});
  };

  const handleClose = () => {
    setConfirmModalIsOpen(false);
  };

  const confirmDeletionPackage = async () => {
    if (!itemToDelete?.itemId || itemToDelete?.index === undefined) {
      return;
    }
    try {
      await DeleteLogisticBillLadingLoad(itemToDelete.itemId);
      remove(itemToDelete.index);

      setHouseMeasurements(false);
      const loadValues = getValues("processLoads");
      const newLoads = {
        logisticLoads: [
          {
            id: loadValues[0]?.id,
            quantities: loadValues[0]?.quantities,
            grossWeight: loadValues[0]?.grossWeight,
            cubicMeters: loadValues[0]?.cubicMeters,
            consideredWeight: loadValues[0]?.consideredWeight,
            cubeWeight: loadValues[0]?.cubeWeight,
          },
        ],
      };

      handleClose();
      await PatchProcessById(+processId, newLoads);
      await refetchProcess();

      showToast("Item removido com sucesso", "success");
    } catch (e: any) {
      console.error(e);
      showAlert(e?.response?.data?.message, "error");
    }
  };

  const getDefaultItemValues = (): CloneCargoItemsOptions[] => {
    const defaultItemValues = billLadingLoads?.map(() => ({
      icon: CloneItem.content_copy,
    }));
    return defaultItemValues;
  };

  const [cloningItemValues, setCloningItemValues] = useState<any>(null);

  const [cloneItemConfig, setCloneItemConfig] = useState<
    CloneCargoItemsOptions[]
  >(getDefaultItemValues());

  const handleClickOnCloneItem = (index: number, valuesToClone: any) => {
    const cloneItems = structuredClone(cloneItemConfig);

    if (cloneItems[index].icon === CloneItem.close) {
      setCloningItemValues(null);
      cloneItems.forEach((item) => (item.icon = CloneItem.content_copy));
      setCloneItemConfig(cloneItems);
      showToast("Clonagem encerrada!");
      setIsCloningItem(false);
      return;
    }

    if (cloneItems[index].icon === CloneItem.content_paste) {
      const dataToClone = structuredClone(cloningItemValues);

      delete dataToClone?.seal;
      delete dataToClone?.barSeal;
      delete dataToClone?.id;
      delete dataToClone?.number;

      update(index, {
        id: getValues("billLadingLoads")[index]?.id,
        ...dataToClone,
      });

      setHouseMeasurements();
      showToast("Dados clonados!", "warning");

      return;
    }

    if (cloneItems[index].icon === CloneItem.content_copy) {
      setCloningItemValues(valuesToClone);
      cloneItems.forEach((item, forIndex) => {
        return forIndex === index
          ? (item.icon = CloneItem.close)
          : (item.icon = CloneItem.content_paste);
      });
      showToast("Item copiado!", "success");

      setCloneItemConfig(cloneItems);

      return;
    }
  };

  const [isCloningItem, setIsCloningItem] = useState(false);

  useEffect(() => {
    setCloneItemConfig(getDefaultItemValues());
  }, [billLadingLoads?.length]);

  return (
    <>
      {confirmModalIsOpen && (
        <ConfirmModal
          isOpen={confirmModalIsOpen}
          title={"Deletar item de carga"}
          subtitle={`Esta ação não pode ser revertida!`}
          confirmButtonFn={() => confirmDeletionPackage()}
          onClose={handleClose}
        />
      )}

      <DefaultCardStructure
        title="Itens Da Carga"
        hasDivider={false}
        isSubcard={false}
        button={
          <>
            <CustomButton
              onClickFn={() => {
                if (isCloningItem) {
                  setCloningItemValues(null);
                  setCloneItemConfig(getDefaultItemValues());
                }
                setIsCloningItem((prev) => !prev);
              }}
              variant="text"
            >
              {isCloningItem ? `Finalizar clonagem` : `Clonar Item`}
            </CustomButton>
            <CustomButton startIcon="add" onClickFn={handleAppendNewItem}>
              Incluir item
            </CustomButton>
          </>
        }
      >
        <Stack sx={{gap: pxToRem(8)}}>
          {!fields?.length && (
            <Typography>Não existem itens de carga no processo.</Typography>
          )}
          {fields?.map((field: any, index) => (
            <CargoItem
              key={index}
              field={field}
              index={index}
              handleDeleteLogisticBillLading={handleDeleteLogisticBillLading}
              setHouseMeasurements={setHouseMeasurements}
              cloneItemConfig={cloneItemConfig[index]}
              handleClickOnCloneItem={handleClickOnCloneItem}
              isCloningItem={isCloningItem}
              loadIndex={loadIndex}
            />
          ))}
        </Stack>
      </DefaultCardStructure>
    </>
  );
};

export default ActivityCargoItems;
