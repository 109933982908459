import {Dialog, Icon, useTheme, Typography, Box} from "@mui/material";
import {Grid} from "@mui/system";

import pxToRem from "hooks/usePxToRem";
import {ReactNode} from "react";
import {CustomButton, CustomIconButton} from "ui";

type Props = {
  isOpen: boolean;
  title: string;
  subtitle: string | ReactNode;
  titleIcon?: string;
  color?: "success" | "warning" | "error" | "primary" | "grey";
  confirmButtonText?: string;
  confirmButtonIconStart?: string;
  confirmButtonIconIsDisabled?: boolean;
  confirmButtonIconEnd?: string;
  confirmButtonFn: () => void;
  confirmButtonTextTransformNone?: boolean;
  onClose: () => void;
  hasOnlyConfirmButton?: boolean;
  children?: ReactNode;
  dataTestId?: string;
  customSecondaryButton?: ReactNode;
};

const ConfirmModal = (props: Props) => {
  const {
    isOpen,
    title,
    titleIcon = "delete",
    subtitle,
    color = "error",
    confirmButtonText = "Excluir",
    confirmButtonIconStart = props?.confirmButtonIconIsDisabled
      ? undefined
      : titleIcon,
    confirmButtonIconEnd,
    confirmButtonFn,
    onClose,
    hasOnlyConfirmButton = false,
    children,
    dataTestId,
    confirmButtonTextTransformNone,
    customSecondaryButton,
  } = props;

  const {palette} = useTheme();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      scroll="paper"
      data-testid={dataTestId}
      PaperProps={{sx: {width: pxToRem(482)}}}
    >
      <Grid container p={pxToRem(20)}>
        <Grid size={10} display="flex" gap={pxToRem(12)}>
          <Box
            sx={{
              height: pxToRem(52),
              width: pxToRem(52),
              borderRadius: pxToRem(5),
              backgroundColor: palette[color][50],
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon sx={{color: palette[color][700], fontSize: pxToRem(30)}}>
              {titleIcon}
            </Icon>
          </Box>
        </Grid>
        <Grid
          size={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <CustomIconButton iconName="close" onClick={onClose} />
        </Grid>

        <Grid container mt={pxToRem(18)} position="relative">
          <Grid size={12}>
            <Typography variant="h4" color={palette.text.primary}>
              {title}
            </Typography>
          </Grid>
          <Grid size={12} mt={pxToRem(2)}>
            <Typography variant="subtitle2" color={palette.text.secondary}>
              {subtitle}
            </Typography>
          </Grid>
          {children}
        </Grid>

        <Grid
          container
          size={12}
          mt={pxToRem(32)}
          gap={pxToRem(12)}
          justifyContent={hasOnlyConfirmButton ? "flex-end" : "space-between"}
          flexWrap={"nowrap"}
        >
          {hasOnlyConfirmButton && !!customSecondaryButton && (
            <Grid size={6}>{customSecondaryButton}</Grid>
          )}
          <Grid size={6}>
            <CustomButton
              startIcon={confirmButtonIconStart}
              endIcon={confirmButtonIconEnd}
              fullWidth
              onClickFn={confirmButtonFn}
              sx={{
                textTransform: confirmButtonTextTransformNone
                  ? "none"
                  : "capitalize",
                color: palette.background.paper,
                backgroundColor: palette[color][600],
                "&:hover": {
                  backgroundColor: palette[color][700],
                },
              }}
            >
              {confirmButtonText}
            </CustomButton>
          </Grid>
          {!hasOnlyConfirmButton && (
            <Grid size={6}>
              <CustomButton
                onClickFn={onClose}
                variant="outlined"
                fullWidth
                sx={{
                  textTransform: "capitalize",
                  color: palette.text.secondary,
                  borderColor: palette.text.secondary,
                  "&:hover": {
                    borderColor: palette.text.primary,
                  },
                }}
              >
                Cancelar
              </CustomButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConfirmModal;
