import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {useEffect, useMemo, useState} from "react";
import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {CustomIconButton} from "ui";
import {getDefaultMRTOptions} from "util/getDefaultMRTOptions";
import {
  DefaultProcessDetails,
  Voyage,
} from "views/ProcessDetails/Process.types";

interface VoyagesTableProps {
  handleEditVoyage: (data: Voyage, index: number) => void;
  getVoyageEditIsDisabled: (index: number) => boolean;
}

const VoyagesTable = (props: VoyagesTableProps) => {
  const allVoyages = useWatch({name: "voyages"});
  const {handleEditVoyage, getVoyageEditIsDisabled} = props;
  const {control, setValue} = useFormContext<DefaultProcessDetails>();
  const {fields: voyages} = useFieldArray({
    control,
    keyName: "idForm",
    name: "voyages",
  });

  const defaultMrtOptions = getDefaultMRTOptions<Voyage>();

  const columns = useMemo<MRT_ColumnDef<Voyage>[]>(
    () => [
      {
        accessorFn: (voyage) => voyage?.typeVoyage?.name,
        header: "Tipo de viagem",
        size: 200,
      },
      {
        accessorFn: (voyage) => voyage?.origin?.name,
        header: "Origem",
        size: 250,
      },

      {
        accessorFn: (voyage) => voyage?.destination?.name,
        header: "Destino",
        size: 250,
      },
      {
        accessorFn: (voyage) => voyage?.vessel?.name,
        header: "Navio",
        size: 190,
      },
      {
        accessorKey: "voyageFlight",
        header: "Viagem",
        size: 170,
      },
      {
        accessorKey: "estimatedCargoLoad",
        header: "ETD",
        size: 120,
      },
      {
        accessorKey: "cargoLoad",
        header: "Embarque",
        size: 120,
      },
      {
        accessorKey: "estimatedCargoUnload",
        header: "ETA",
        size: 120,
      },
      {
        accessorKey: "cargoUnload",
        header: "Desembarque",
        size: 170,
      },
      {
        accessorKey: "placePickup",
        header: "Local de coleta",
        size: 170,
      },
    ],
    [],
  );

  const [voyagesCopy, setVoyagesCopy] = useState(allVoyages);

  useEffect(() => {
    setVoyagesCopy(allVoyages);
  }, [allVoyages]);

  const data = voyagesCopy as unknown as Voyage[];

  const handleSortRows = (draggingRow, hoveredRow) => {
    const tempVoyages = voyagesCopy.map((item, index) => ({
      ...item,
      precedence:
        index === draggingRow.index
          ? hoveredRow.original.precedence
          : index === hoveredRow.index
            ? draggingRow.original.precedence
            : item.precedence,
    }));

    tempVoyages[draggingRow.index] = {
      ...voyagesCopy[hoveredRow.index],
      precedence: draggingRow.original.precedence,
    };
    tempVoyages[hoveredRow.index] = {
      ...voyagesCopy[draggingRow.index],
      precedence: hoveredRow.original.precedence,
    };

    setVoyagesCopy(tempVoyages);

    const voyagesWithNewPrecedence: Voyage[] = voyages.map((item) => {
      const sameIdItem = tempVoyages?.find((it) => it.id === item.id);
      return {...item, precedence: sameIdItem?.precedence ?? 0};
    });

    setValue("voyages", voyagesWithNewPrecedence, {shouldDirty: true});
  };

  const table = useMaterialReactTable({
    ...defaultMrtOptions,
    columns,
    data,
    enableRowOrdering: true,
    muiRowDragHandleProps: ({table}) => ({
      onDragEnd: () => {
        const {draggingRow, hoveredRow} = table.getState();
        if (draggingRow && hoveredRow) {
          handleSortRows(draggingRow, hoveredRow);
        }
      },
    }),
    renderRowActions: ({row}) => (
      <CustomIconButton
        iconName={"edit"}
        disabled={getVoyageEditIsDisabled(row.index)}
        onClick={() => handleEditVoyage(row.original, row.index)}
      />
    ),
  });

  return <MaterialReactTable table={table} />;
};

export default VoyagesTable;
