import {ReactNode} from "react";
import {
  Box,
  Breakpoint,
  Chip,
  Dialog,
  Divider,
  Icon,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import {Grid} from "@mui/system";

import {CustomIconButton} from "ui";
import pxToRem from "hooks/usePxToRem";
import ClientClassificationChip from "components/ClientClassificationChip";
import {Classifications} from "views/ProcessDetails/Process.types";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  titleIcon: string;
  children?: ReactNode;
  hideDivider?: boolean;
  maxWidth?: false | Breakpoint;
  subtitle?: string;
  dividerBadge?: string | ReactNode;
  paperWidth?: string;
  titleEndIcon?: ReactNode;
  dataTestId?: string;
  sx?: SxProps<Theme>;
  paperOverflow?: string;
  classificationBadge?: Classifications;
  disableEscapeKeyDown?: boolean;
};

const CustomModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    title,
    titleIcon,
    children,
    hideDivider = false,
    maxWidth,
    subtitle,
    dividerBadge,
    paperWidth,
    titleEndIcon,
    dataTestId,
    sx,
    paperOverflow,
    classificationBadge,
    disableEscapeKeyDown,
  } = props;

  const theme = useTheme();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      scroll="paper"
      maxWidth={maxWidth}
      data-testid={dataTestId}
      PaperProps={{
        sx: {width: paperWidth ?? "100%", overflow: paperOverflow ?? "auto"},
      }}
      sx={sx}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <Grid
        container
        p={`${pxToRem(20)} ${pxToRem(20)} ${pxToRem(16)} ${pxToRem(20)}`}
      >
        <Grid size={11} display="flex" alignItems={"center"} gap={pxToRem(12)}>
          <Box
            sx={{
              height: pxToRem(36),
              width: pxToRem(36),
              borderRadius: pxToRem(5),
              backgroundColor: theme.palette.primary[50],
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon sx={{color: theme.palette.primary[700]}}>{titleIcon}</Icon>
          </Box>
          <Stack alignItems="flex-start" gap={pxToRem(5)}>
            <Stack direction="row" alignItems={"center"} gap={pxToRem(15)}>
              <Typography variant="h4">{title}</Typography>
              {classificationBadge && (
                <ClientClassificationChip
                  classification={classificationBadge}
                />
              )}
            </Stack>
            <Typography
              variant="subtitle2"
              sx={{color: theme.palette.text.secondary}}
            >
              {subtitle}
            </Typography>
          </Stack>
          {titleEndIcon && (
            <Stack
              alignItems="flex-start"
              alignSelf="flex-start"
              ml={pxToRem(-8)}
            >
              {titleEndIcon}
            </Stack>
          )}
        </Grid>
        <Grid
          size={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <CustomIconButton iconName="close" onClick={onClose} />
        </Grid>
      </Grid>

      <Grid container size={12}>
        <Grid size={12}>
          <Divider sx={{display: hideDivider ? "none" : ""}}>
            {dividerBadge && typeof dividerBadge === "string" ? (
              <Stack display="flex">
                <Chip label={dividerBadge} color="primary" />
              </Stack>
            ) : (
              dividerBadge
            )}
          </Divider>
        </Grid>
      </Grid>

      {children}
    </Dialog>
  );
};

export default CustomModal;
