import pxToRem from "hooks/usePxToRem";

import {Box, TextField} from "@mui/material";
import {Grid} from "@mui/system";

import {CustomInput, CustomInputWrapper, CustomSelect, CustomToggle} from "ui";
import {DefaultCardStructure, ProcessDatePicker} from "components";
import {ModalityPayment, PlaceIssue} from "util/globalEnums";
import {useFormContext, Controller} from "react-hook-form";

const MasterInfo = () => {
  const {control, getValues} = useFormContext();

  const inputWrapperGridSize = 2;

  return (
    <DefaultCardStructure
      title="BL Master"
      hasDivider={getValues("masterBl.directMaster")}
      isSubcard
    >
      <Grid container rowSpacing={pxToRem(20)} columnSpacing={pxToRem(8)}>
        <CustomInputWrapper xs={inputWrapperGridSize} title="BL n°">
          <Controller
            name="masterBL.number"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="masterBL.number"
                type="text"
                placeholder="BL n°"
                value={value ?? ""}
                onChange={onChange}
                size="small"
              />
            )}
          />

          {getValues("masterBl.directMaster") && (
            <Box
              sx={{
                marginTop: pxToRem(-4),
                maxHeight: pxToRem(32),
                ml: pxToRem(5),
              }}
            >
              <Controller
                name="masterBL.directMaster"
                control={control}
                render={({field: {onChange, value}}) => (
                  <CustomToggle
                    name="masterBL.directMaster"
                    label="BL Direto"
                    labelPosition="end"
                    onToggleSwitch={onChange}
                    isChecked={value}
                    isDisabled
                  />
                )}
              />
            </Box>
          )}
        </CustomInputWrapper>
        <CustomInputWrapper xs={inputWrapperGridSize} title="Data BL">
          <Controller
            name="masterBL.dateIssue"
            control={control}
            render={({field: {value}}) => (
              <ProcessDatePicker
                value={value}
                name={`masterBL.dateIssue`}
                title="Data BL"
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper
          xs={inputWrapperGridSize}
          title="Modelo De Pagamento"
          isDisabled
        >
          <Controller
            name="masterBL.paymentMethod"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomSelect
                name="masterBL.paymentMethod"
                value={value ?? ""}
                onChange={onChange}
                content={Object.values(ModalityPayment)}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper xs={inputWrapperGridSize} title="CE n°" isDisabled>
          <Controller
            name="masterBL.deconsolidationNumber"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                name="masterBL.deconsolidationNumber"
                value={value ?? ""}
                placeholder="CE n°"
                onChange={onChange}
                size="small"
                disabled
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper xs={inputWrapperGridSize} title="Data CE">
          <Controller
            name="masterBL.dateDeconsolidation"
            control={control}
            render={({field: {value}}) => (
              <ProcessDatePicker
                value={value}
                name={`masterBL.dateDeconsolidation`}
                title="Data CE"
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper xs={inputWrapperGridSize} title="Local Emissão">
          <Controller
            name="masterBL.placeIssue"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomSelect
                name="masterBL.placeIssue"
                value={value}
                onChange={onChange}
                content={Object.values(PlaceIssue)}
                size="small"
              />
            )}
          />
        </CustomInputWrapper>{" "}
        {!getValues("masterBl.directMaster") && (
          <Grid size={inputWrapperGridSize} ml={pxToRem(5)}>
            <Controller
              name="masterBL.directMaster"
              control={control}
              render={({field: {onChange, value}}) => (
                <CustomToggle
                  name="masterBL.directMaster"
                  label="BL Direto"
                  labelPosition="end"
                  isDisabled
                  onToggleSwitch={onChange}
                  isChecked={value ?? ""}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      {getValues("masterBL.directMaster") && (
        <CustomInputWrapper md={12} title="Descrição dos pacotes">
          <Controller
            name={`masterBL.logisticBillLading.descriptionOfPackages` as const}
            control={control}
            render={({field: {value, onChange}}) => (
              <TextField
                multiline
                minRows={4}
                maxRows={8}
                size="small"
                name={`masterBL.logisticBillLading.descriptionOfPackages`}
                value={value ?? ""}
                placeholder="descrição"
                onChange={onChange}
                sx={{fontSize: pxToRem(14)}}
              />
            )}
          />
        </CustomInputWrapper>
      )}
    </DefaultCardStructure>
  );
};

export default MasterInfo;
