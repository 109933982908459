import {TYPE_VOYAGES_TO_SHOW} from "global/constants";
import useAlertMessage from "hooks/useAlertMessage";
import {useState} from "react";
import {GetVoyageTypes} from "services/api_v2/Processes/Processes.service";

const useVoyageTypes = () => {
  const [showAlert] = useAlertMessage();

  const [loading, setLoading] = useState(false);

  const changeLabelToDTA = (voyageTypes: {id: number; label: string}[]) => {
    const result = voyageTypes?.map((item) =>
      item.label === "Destino final"
        ? {...item, label: "DTA"}
        : {...item, label: item?.label},
    );

    return result;
  };

  const getVoyageTypes = async (setVoyageTypes) => {
    try {
      setLoading(true);
      const {
        data: {data},
      } = await GetVoyageTypes();

      const voyageTypesAutocompleteData: {id: number; label: string}[] =
        data.map((item) => ({
          label: item?.name,
          id: item?.id,
        }));

      const voyageTypesToShow = voyageTypesAutocompleteData.filter((item) =>
        TYPE_VOYAGES_TO_SHOW.includes(item?.label),
      );

      const voyageTypesToShowWithDTA = changeLabelToDTA(voyageTypesToShow);

      setVoyageTypes(voyageTypesToShowWithDTA);
    } catch (error: any) {
      console.error(error);
      showAlert(error?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  return {getVoyageTypes, loading};
};

export default useVoyageTypes;
