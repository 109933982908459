import {Chip, useTheme} from "@mui/material";
import {Grid, Box} from "@mui/system";
import {containerNumberLength} from "global/constants";
import {useDebounceCallback} from "hooks/useDebounceCallback";
import pxToRem from "hooks/usePxToRem";
import {useEffect, useState} from "react";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {
  GetEquipmentsList,
  GetPackageContainersList,
} from "services/api_v2/Processes/Processes.service";
import {
  CustomInputWrapper,
  CustomSelect,
  CustomInput,
  CustomAutocomplete,
  CustomIconButton,
} from "ui";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import {TypeItemLoad} from "util/globalEnums";
import ProcessInput from "views/ProcessDetails/components/ProcessInput";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {ProcessEquipment} from "views/ProcessDetails/Process.types";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";

type Props = {
  field: any;
  index: number;
  loadIndex: number;
  handleDeleteLogisticBillLading: (field: any, index: number) => Promise<void>;
  setHouseMeasurements: (shouldDirtyFields?: boolean) => void;
  cloneItemConfig: {
    icon: "content_copy" | "content_paste" | "close";
  };
  handleClickOnCloneItem: (index: number, valuesToClone: any) => void;
  isCloningItem: boolean;
};

const Item = (props: Props) => {
  const {
    cloneItemConfig,
    isCloningItem,
    handleClickOnCloneItem,
    field,
    index,
    setHouseMeasurements,
    handleDeleteLogisticBillLading,
  } = props;

  const {
    control,
    setValue,
    getValues,
    register,
    clearErrors,
    formState: {errors},
    watch,
  } = useFormContext();
  const cancelledProcess =
    useWatch({name: "agencyingSituation"}) === "Cancelado";

  const cargoItems: Array<{[key: number]: boolean}> = getValues(
    `logisticBillLadingLoads`,
  );
  const [errorsState, setErrorsState] = useState(
    cargoItems.reduce((acc, _, index) => ({...acc, [index]: false}), {}),
  );
  const [equipmentsOptions, setEquipmentsOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [search, setSearch] = useState<string>();

  const {
    getOptions: getEquipmentsOptions,
    onBottomScroll: onBottomScrollEquipments,
    isLoadingOptions: isLoadingEquipmentsOptions,
  } = useHandleFieldLists(GetEquipmentsList);

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();

  const setHouseMeasurementsDebounced = useDebounceCallback(
    setHouseMeasurements,
    1000,
  );

  const getEquipmentsIds = () => {
    const equipments: ProcessEquipment[] = getValues("processEquipments");
    const idsList = equipments?.map((item) => item?.equipment?.id);
    return idsList;
  };

  const [equipmentsIdsList] =
    useState<(number | undefined)[]>(getEquipmentsIds());

  const equipmentsList: ProcessEquipment[] = useWatch({
    name: "processEquipments",
  });
  const getEquipmentsQuantity = () => {
    const totalOfEquipments = equipmentsList?.reduce(
      (acc, curr) => acc + curr?.quantity,
      0,
    );
    return totalOfEquipments;
  };

  useEffect(() => {
    getEquipmentsQuantity();
  }, []);

  const fieldIsRequired = (index: number) => {
    const number = getValues(`logisticBillLadingLoads.${index}.number`);
    const seal = getValues(`logisticBillLadingLoads.${index}.seal`);
    const equipment = getValues(`logisticBillLadingLoads.${index}.equipmentId`);
    const quantity = getValues(`logisticBillLadingLoads.${index}.quantity`);
    const netWeight = getValues(`logisticBillLadingLoads.${index}.netWeight`);
    const grossWeight = getValues(
      `logisticBillLadingLoads.${index}.grossWeight`,
    );
    const measurement = getValues(
      `logisticBillLadingLoads.${index}.measurement`,
    );
    const packageContainer = getValues(
      `logisticBillLadingLoads.${index}.packageContainer`,
    );
    const typeItemLoad = getValues(
      `logisticBillLadingLoads.${index}.typeItemLoad`,
    );

    const typePackages = getValues(
      `logisticBillLadingLoads.${index}.typePackages`,
    );
    const dangerousGoodsClass = getValues(
      `logisticBillLadingLoads.${index}.dangerousGoodsClass`,
    );
    const dangerousGoodsCode = getValues(
      `logisticBillLadingLoads.${index}.dangerousGoodsCode`,
    );
    if (number !== "" && number !== null) {
      clearErrors(`processDate.N° do container`);
    }

    if (getValues(`logisticBillLadingLoads.${index}.id`)) {
      return true;
    }
    if (
      seal !== "" ||
      equipment !== undefined ||
      quantity !== 0 ||
      netWeight !== 0 ||
      grossWeight !== 0 ||
      measurement !== 0 ||
      packageContainer !== undefined ||
      typePackages !== null ||
      typeItemLoad !== "" ||
      dangerousGoodsClass !== "" ||
      dangerousGoodsCode !== ""
    ) {
      setErrorsState((prev) => ({...prev, [index]: true}));
      return true;
    }

    return false;
  };

  const [packagesOptions, setPackagesOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [searchPackages, setSearchPackages] = useState<string>();

  const {
    getOptions: getPackagesOptions,
    onBottomScroll: onBottomScrollPackages,
    isLoadingOptions: isLoadingPackagesOptions,
  } = useHandleFieldLists(GetPackageContainersList);

  const [
    extraSpaceWhenCargoIsNotDangerous,
    setExtraSpaceWhenCargoIsNotDangerous,
  ] = useState(0);

  const DANGEROUS_FIELD_SIZE = 1;
  const [isCargoDangerous] = useState(
    getValues(`processLoads[0].cargoDangerous`),
  );

  useEffect(() => {
    if (!isCargoDangerous) {
      setExtraSpaceWhenCargoIsNotDangerous((2 * DANGEROUS_FIELD_SIZE) / 10);
    }
  }, []);

  const theme = useTheme();

  const getTooltipTextAndIconColor = (icon) => {
    if (icon === "content_copy")
      return {text: "Copiar item", iconColor: theme.palette.text.primary};

    if (icon === "content_paste")
      return {
        text: "Colar item copiado aqui",
        iconColor: theme.palette.info[500],
      };

    if (icon === "close")
      return {text: "Encerrar clonagem", iconColor: theme.palette.error[500]};
    return {text: ""};
  };

  return (
    <Grid key={field.idForm} container columnSpacing={pxToRem(1)}>
      {isCloningItem && (
        <CustomInputWrapper title="" xs={0.3}>
          <CustomIconButton
            iconName={cloneItemConfig?.icon}
            onClick={() => {
              handleClickOnCloneItem(
                index,
                getValues("logisticBillLadingLoads")[index],
              );
              fieldIsRequired(index);
            }}
            tooltipText={getTooltipTextAndIconColor(cloneItemConfig.icon).text}
            sx={{
              ":hover": {
                color: getTooltipTextAndIconColor(cloneItemConfig.icon)
                  .iconColor,
              },
            }}
          />
        </CustomInputWrapper>
      )}
      <CustomInputWrapper xs={0.4} title="">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Chip label={index + 1} color="primary" size="small" />
        </Box>
      </CustomInputWrapper>

      <CustomInputWrapper
        title="Tipo item"
        xs={0.6 + extraSpaceWhenCargoIsNotDangerous + (isCloningItem ? 0 : 0.3)}
        tooltip="Tipo item carga"
      >
        <Controller
          name={`logisticBillLadingLoads.${index}.typeItemLoad`}
          control={control}
          render={({field: {onChange, value}}) => (
            <CustomSelect
              content={Object.values(TypeItemLoad)}
              value={value ?? ""}
              name={`logisticBillLadingLoads.${index}.typeItemLoad`}
              onChange={(e) => {
                onChange(e);
                fieldIsRequired(index);
              }}
              size="small"
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        xs={0.8 + extraSpaceWhenCargoIsNotDangerous}
        title="C. Num "
      >
        <ProcessInput
          {...register(`logisticBillLadingLoads.${index}.number`, {
            pattern: {
              value: /^[A-Za-z]{4}\d{7}$/,
              message: "Número do container. Usar formato: AAAA1111111.",
            },
            validate: (value) => {
              const isDuplicate = watch("logisticBillLadingLoads")?.some(
                (item, i) =>
                  i !== index &&
                  item.number.length === containerNumberLength &&
                  item.number === value,
              );
              return isDuplicate &&
                watch(`processLoads.${index}.typeLoad`) === "FCL"
                ? `Item de carga ${index + 1} - Número de container duplicado.`
                : true;
            },

            onChange: () => fieldIsRequired(index),
          })}
          placeholder="N° container"
          error={errors?.logisticBillLadingLoads?.[index]?.number}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        xs={0.8 + extraSpaceWhenCargoIsNotDangerous}
        title="Selo"
      >
        <Controller
          name={`logisticBillLadingLoads.${index}.seal`}
          control={control}
          render={({field: {value, onChange}}) => (
            <CustomInput
              name={`logisticBillLadingLoads.${index}.seal`}
              type="text"
              placeholder="Selo"
              value={value ?? ""}
              onChange={(e) => {
                onChange(e);
                fieldIsRequired(index);
              }}
              size="small"
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        xs={1 + extraSpaceWhenCargoIsNotDangerous}
        title="BarSeal"
      >
        <Controller
          name={`logisticBillLadingLoads.${index}.barSeal`}
          control={control}
          render={({field: {value, onChange}}) => (
            <CustomInput
              name={`logisticBillLadingLoads.${index}.barSeal`}
              type="text"
              placeholder="BarSeal"
              value={value ?? ""}
              onChange={(e) => {
                onChange(e);
                fieldIsRequired(index);
              }}
              size="small"
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        xs={1.2 + extraSpaceWhenCargoIsNotDangerous}
        title="Equipamento"
      >
        <Controller
          name={`logisticBillLadingLoads.${index}.equipment`}
          control={control}
          render={({field: {value}}) => (
            <CustomAutocomplete
              name={`logisticBillLadingLoads.${index}.equipment`}
              placeholder="Equipamento"
              tooltipText={getTooltipText(value?.name, 3)}
              loading={isLoadingEquipmentsOptions}
              options={mountOptions(value, equipmentsOptions)}
              value={value?.name}
              onOpen={() => getEquipmentsOptions(setEquipmentsOptions, 1, "")}
              onInputChange={(e, value) => {
                if (e !== null) {
                  setSearch(value);
                  getEquipmentsOptions(setEquipmentsOptions, 1, search);
                }
              }}
              getNextPage={() => {
                onBottomScrollEquipments(setEquipmentsOptions, search);
              }}
              onChange={(e, value) => {
                handleChangeAutocomplete(
                  e,
                  value,
                  `logisticBillLadingLoads.${index}.equipment`,
                );
                setSearch("");
                setEquipmentsOptions([]);
                fieldIsRequired(index);
              }}
              isOptionEqualToValue={(option, value) => value === option?.label}
              getOptionDisabled={(option) =>
                !equipmentsIdsList?.includes(option?.id)
              }
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        xs={0.5 + extraSpaceWhenCargoIsNotDangerous}
        title="Quant."
        tooltip="Quantidade"
      >
        <Controller
          name={`logisticBillLadingLoads.${index}.quantity`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`logisticBillLadingLoads.${index}.quantity`}
              type="number"
              placeholder="Quant."
              value={value ?? ""}
              onChange={(e) => {
                setValue(
                  `logisticBillLadingLoads.${index}.quantity`,
                  +e.target.value,
                  {shouldDirty: true},
                );
                setHouseMeasurementsDebounced();

                fieldIsRequired(index);
              }}
              size="small"
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 1.0,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
              required
            />
          )}
        />
      </CustomInputWrapper>
      <CustomInputWrapper
        xs={1.2 + extraSpaceWhenCargoIsNotDangerous}
        title={`Pacote`}
      >
        <Controller
          name={`logisticBillLadingLoads.${index}.packageContainer`}
          control={control}
          render={({field: {value}}) => (
            <CustomAutocomplete
              name={`logisticBillLadingLoads.${index}.packageContainer`}
              placeholder="Pacote"
              tooltipText={getTooltipText(value?.name, 3)}
              loading={isLoadingPackagesOptions}
              options={mountOptions(value, packagesOptions)}
              value={value?.name}
              onOpen={() => getPackagesOptions(setPackagesOptions, 1, "")}
              onInputChange={(e, value) => {
                if (e !== null) {
                  setSearchPackages(value);
                  getPackagesOptions(setPackagesOptions, 1, searchPackages);
                }
              }}
              getNextPage={() => {
                onBottomScrollPackages(setPackagesOptions, searchPackages);
              }}
              onChange={(e, value) => {
                handleChangeAutocomplete(
                  e,
                  value,
                  `logisticBillLadingLoads.${index}.packageContainer`,
                );
                setSearchPackages("");
                setPackagesOptions([]);
              }}
              isOptionEqualToValue={(option, value) => value === option?.label}
            />
          )}
        />
      </CustomInputWrapper>
      {/* Peso líquido oculto por enquanto */}
      {/* <CustomInputWrapper md={1.5} title="Peso líq.">
                <Controller
                  name={`logisticBillLadingLoads.${index}.netWeight`}
                  control={control}
                  render={({field: {value}}) => (
                    <CustomInput
                      name={`logisticBillLadingLoads.${index}.netWeight`}
                      type="number"
                      placeholder="Peso liq."
                      value={value ?? ""}
                      onChange={(e) => {
                        setValue(
                          `logisticBillLadingLoads.${index}.netWeight`,
                          +e.target.value,
                          {shouldDirty: true},
                        );
                        fieldIsRequired(index);
                      }}
                      size="small"
                      inputProps={{
                        style: {
                          fontSize: pxToRem(14),
                        },
                        min: 0.0,
                        step: 0.001,
                      }}
                    />
                  )}
                />
              </CustomInputWrapper> */}
      <CustomInputWrapper
        xs={1 + extraSpaceWhenCargoIsNotDangerous}
        title="P. Bruto"
        tooltip="Peso bruto - kg"
      >
        <Controller
          name={`logisticBillLadingLoads.${index}.grossWeight`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`logisticBillLadingLoads.${index}.grossWeight`}
              type="number"
              placeholder="Peso bruto"
              value={value ?? ""}
              onChange={(e) => {
                setValue(
                  `logisticBillLadingLoads.${index}.grossWeight`,
                  +e.target.value,
                  {shouldDirty: true},
                );
                setHouseMeasurementsDebounced();
                fieldIsRequired(index);
              }}
              size="small"
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        xs={1 + extraSpaceWhenCargoIsNotDangerous}
        title="CBM"
      >
        <Controller
          name={`logisticBillLadingLoads.${index}.measurement`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`logisticBillLadingLoads.${index}.measurement`}
              type="number"
              placeholder="medidas"
              value={value ?? ""}
              onChange={(e) => {
                setValue(
                  `logisticBillLadingLoads.${index}.measurement`,
                  +e.target.value,
                  {shouldDirty: true},
                );

                setHouseMeasurementsDebounced();
                fieldIsRequired(index);
              }}
              size="small"
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
            />
          )}
        />
      </CustomInputWrapper>

      <CustomInputWrapper
        md={1 + extraSpaceWhenCargoIsNotDangerous}
        title="Tara"
      >
        <Controller
          name={`logisticBillLadingLoads.${index}.tare`}
          control={control}
          render={({field: {value}}) => (
            <CustomInput
              name={`logisticBillLadingLoads.${index}.tare`}
              type="number"
              placeholder="Tara"
              value={value}
              onChange={(e) => {
                setValue(
                  `logisticBillLadingLoads.${index}.tare`,
                  +e.target.value,
                  {shouldDirty: true},
                );
              }}
              size="small"
              inputProps={{
                style: {
                  fontSize: pxToRem(14),
                },
                min: 0.0,
                step: 0.001,
                onClick: (e) => value === 0 && e.currentTarget.select(),
              }}
            />
          )}
        />
      </CustomInputWrapper>

      {isCargoDangerous && (
        <>
          <CustomInputWrapper
            xs={1 + extraSpaceWhenCargoIsNotDangerous}
            title="Classe"
            tooltip="Classe mercadoria perigosa"
          >
            <ProcessInput
              {...register(
                `logisticBillLadingLoads.${index}.dangerousGoodsClass`,
                {
                  onChange: () => fieldIsRequired(index),
                },
              )}
              placeholder="Classe"
            />
          </CustomInputWrapper>

          <CustomInputWrapper
            xs={1}
            title="Cód."
            tooltip="Cód. mercadoria perigosa"
          >
            <ProcessInput
              {...register(
                `logisticBillLadingLoads.${index}.dangerousGoodsCode`,
                {
                  onChange: () => fieldIsRequired(index),
                },
              )}
              placeholder="Classe"
            />
          </CustomInputWrapper>
        </>
      )}

      <Grid
        size={0.2}
        display="flex"
        alignItems="end"
        justifyContent="flex-start"
      >
        <CustomIconButton
          iconName="delete"
          disabled={cancelledProcess || isCloningItem}
          sx={{
            pr: 0,
            ":hover": {
              color: "red",
            },
          }}
          onClick={() => {
            handleDeleteLogisticBillLading(field, index);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Item;
