import {useEffect, useState} from "react";
import {
  GetProcessById,
  PatchProcessById,
} from "services/api_v2/Processes/Processes.service";
import {
  BillLadingLoads,
  Knowledge,
  LogisticEquipment,
  Ncm,
  OriginAgent,
  Process,
  ProcessCharges,
  ProcessLoad,
  ProcessPackage,
  Voyage,
} from "./ProcessSectionView.types";
import {format} from "date-fns";
import {getDateMinusTimezone} from "util/getISODate";
import useValueOrEmpty from "hooks/useValueOrEmpty";
import useAlertMessage from "hooks/useAlertMessage";
import useLoading from "hooks/useLoading";

const useProcessSectionViewData = (processId: number) => {
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValues, setDefaultValues] = useState<Process>();
  const {valueOrEmptyString} = useValueOrEmpty();
  const [showAlert] = useAlertMessage();
  const [setLoading] = useLoading();

  const mountKnowledges = (knowledges: Knowledge[]): Knowledge[] => {
    const isDirectMaster = knowledges.find(
      (item) => item.typeKnowledge === "Master" && item.directMaster,
    );

    const shouldHaveBillLadingLoads = (typeKnowledge: "House" | "Master") => {
      if (isDirectMaster && typeKnowledge === "House") {
        return false;
      }

      if (!isDirectMaster && typeKnowledge === "House") {
        return true;
      }

      if (isDirectMaster && typeKnowledge === "Master") {
        return true;
      }

      if (!isDirectMaster && typeKnowledge === "Master") {
        return false;
      }
    };

    const result = knowledges?.map((item) => ({
      id: item.id,
      idHc: item.idHc,
      number: item.number,
      typeKnowledge: item.typeKnowledge,
      dateDeconsolidation: !item.dateDeconsolidation
        ? ""
        : format(
            getDateMinusTimezone(valueOrEmptyString(item?.dateDeconsolidation)),
            "yyyy-MM-dd",
          ),
      dateIssue: !item.dateIssue
        ? ""
        : format(
            getDateMinusTimezone(valueOrEmptyString(item.dateIssue)),
            "yyyy-MM-dd",
          ),
      deconsolidationNumber: item.deconsolidationNumber,
      directMaster: Boolean(item.directMaster),
      isActive: item.isActive,
      logisticBillLading: {
        ...item.logisticBillLading,
        logisticBillLadingLoads: shouldHaveBillLadingLoads(item.typeKnowledge)
          ? item.logisticBillLading?.logisticBillLadingLoads
          : undefined,
      },
      observation: item.observation,
      paymentMethod: item.paymentMethod,
      placeIssue: valueOrEmptyString(item?.placeIssue),
    }));
    return result;
  };

  const mountHouse = (knowledges: Knowledge[]): Knowledge | undefined => {
    const house = knowledges.find((item) => item.typeKnowledge === "House");

    if (!house) {
      const newHouse: Knowledge = {} as Knowledge;
      return newHouse;
    }

    return house;
  };

  const mountMaster = (knowledges: Knowledge[]): Knowledge | undefined => {
    const master = knowledges.find((item) => item.typeKnowledge === "Master");
    return master;
  };

  const getNumberOfNewContainers = (
    knowledges: Knowledge | undefined,
    totalOfEquipments: number,
  ) => {
    if (!knowledges) return 0;

    const existentCargoItems =
      knowledges?.logisticBillLading?.logisticBillLadingLoads?.length ?? 0;

    if (Object.keys(knowledges).length === 0) {
      return 0;
    }
    const total = totalOfEquipments - existentCargoItems;

    if (total > 0) {
      return total;
    } else {
      return 0;
    }
  };

  const mountBillLadingLoads = (
    knowledges: Knowledge | undefined,
    totalOfEquipments: number,
  ): BillLadingLoads[] => {
    if (!knowledges) return [];

    const result = knowledges?.logisticBillLading?.logisticBillLadingLoads?.map(
      (load) => ({
        id: load.id,
        number: load.number,
        seal: load.seal,
        equipment: {
          id: load?.equipment?.id,
          name: load?.equipment?.name,
        },
        quantity: load.quantity,
        netWeight: load.netWeight,
        grossWeight: load.grossWeight,
        measurement: load.measurement,
        tare: load.tare,
        typeItemLoad: load.typeItemLoad,

        barSeal: load.barSeal,
        packageContainer: {
          id: load.packageContainer?.id,
          name: load.packageContainer?.name,
        },
        typePackages: {
          id: load.typePackages?.id,
          name: load.typePackages?.name,
        },
        dangerousGoodsClass: load.dangerousGoodsClass,
        dangerousGoodsCode: load.dangerousGoodsCode,
      }),
    );

    if (!result) return [];

    const newLadingLoads = new Array(
      getNumberOfNewContainers(knowledges, totalOfEquipments),
    ).fill({
      number: "",
      seal: "",
      equipment: null,
      quantity: 0,
      netWeight: 0,
      grossWeight: 0,
      measurement: 0,
      tare: 0,
      typeItemLoad: "",
      barSeal: "",
      typePackages: null,
      packageContainer: null,
      dangerousGoodsClass: "",
      dangerousGoodsCode: "",
    });
    return [...result, ...newLadingLoads];
  };

  const mountPackages = (
    processPackages: ProcessPackage[],
  ): ProcessPackage[] => {
    const result = processPackages?.map((item) => ({
      id: item.id,
      consideredWeight: item.consideredWeight,
      cubedWeight: item.cubedWeight,
      cubicMeters: item.cubicMeters,
      grossWeight: item.grossWeight,
      grossWeightUnit: item.grossWeightUnit,
      height: item.height,
      idLogisticProcess: item.idLogisticProcess,
      isActive: item.isActive,
      length: item.length,
      netWeight: item.netWeight,
      netWeightUnit: item.netWeightUnit,
      package: {
        id: item.package.id,
        name: item.package.name,
      },
      quantity: item.quantity,
      width: item.width,
    }));
    return result;
  };

  const mountNcms = (ncms: Ncm[]): Ncm[] => {
    const result = ncms?.map((item) => ({
      id: item.id,
      idNcm: item.idNcm,
      isActive: item.isActive,
      ncm: {
        id: item.ncm.id,
        code: item.ncm.code,
        description: item.ncm.description,
        isActive: item.ncm.isActive,
      },
    }));
    return result;
  };

  const mountProcessLoads = (loads: ProcessLoad[]): ProcessLoad[] => {
    const result = loads?.map((item) => ({
      id: item.id,
      cubicMeters: item.cubicMeters,
      grossWeight: item.grossWeight,
      consideredWeight: item.consideredWeight,
      cubeWeight: item.cubeWeight,
      quantities: item.quantities,
      typeLoad: item.typeLoad,
      woodPackage: item.woodPackage,
      cargoDangerous: item.cargoDangerous,
      commodity: item.commodity,
      commodityValue: item.commodityValue,
      currencyCommodity: item.currencyCommodity,
    }));
    return result;
  };

  const mountEquipments = (equipments: LogisticEquipment[]) => {
    const result = equipments?.map((item) => ({
      id: item.id,
      equipment: {
        id: item.equipment.id,
        name: item.equipment.name,
      },
      quantity: item.quantity,
    }));

    return result;
  };

  const mountProcessCharges = (charges: any[]) => {
    const result: ProcessCharges[] = charges?.map((item) => ({
      id: item?.id,
      rate: valueOrEmptyString(item?.displayCharge?.name),
      equipment: valueOrEmptyString(item?.equipment?.name),
      paymentCurrency: valueOrEmptyString(item?.paymentCurrency?.abbreviation),
      totalPaymentAmount: valueOrEmptyString(item?.quantityPayment),
      totalReceiptAmount: valueOrEmptyString(item?.quantityReceipt),
      receiptCurrency: valueOrEmptyString(item?.receiptCurrency?.abbreviation),
      idKnowledgeHouse: valueOrEmptyString(
        item?.logisticKnowledgeHouse?.id?.toString(),
      ),

      //novo
      displayCharge: valueOrEmptyString(item?.displayCharge?.name),
      originCharge: valueOrEmptyString(item?.originCharge),
      dateExpiry: !item?.dateExpiry
        ? ""
        : format(getDateMinusTimezone(item?.dateExpiry), "yyyy-MM-dd"),
      typeService: valueOrEmptyString(item?.typeAwb), //aqui sem certeza
      //pagamento:
      modalityPayment: valueOrEmptyString(item?.modalityPayment),
      payTo: valueOrEmptyString(item?.payTo),
      paymentPerson: {
        name: valueOrEmptyString(item?.paymentPerson?.name),
        id: item?.paymentPerson?.id,
      },
      typePayment: valueOrEmptyString(item?.typePayment),
      typeReceipt: valueOrEmptyString(item?.typeReceipt),

      billingPayment: valueOrEmptyString(item?.billingPayment),
      quantityPayment: Number(
        valueOrEmptyString(item?.quantityPayment),
      ).toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
      }), //quantidade
      valuePaymentUnit: Number(
        valueOrEmptyString(item?.valuePaymentUnit),
      ).toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
      }), //dinheiro
      valuePaymentMinimum: Number(
        valueOrEmptyString(item?.valuePaymentMinimum),
      ).toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
      }), //dinheiro
      valuePaymentTotal: Number(
        valueOrEmptyString(item?.valuePaymentTotal),
      ).toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
      }), //dinheiro

      //recebimento
      billingReceipt: valueOrEmptyString(item?.billingReceipt),
      valueReceiptUnit: Number(
        valueOrEmptyString(item?.valueReceiptUnit),
      ).toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
      }), //dinheiro
      valueReceiptMinimum: Number(
        valueOrEmptyString(item?.valueReceiptMinimum),
      ).toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
      }), //dinheiro
      valueReceiptTotal: Number(
        valueOrEmptyString(item?.valueReceiptTotal),
      ).toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
      }), //dinheiro
      receiptPerson: valueOrEmptyString(item?.receiptPerson),
      receiveFrom: valueOrEmptyString(item?.receiveFrom),
      quantityReceipt: Number(
        valueOrEmptyString(item?.quantityReceipt),
      ).toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
      }), //quantidade
      modalityReceipt: valueOrEmptyString(item?.modalityReceipt),
    }));
    return result;
  };

  const mountOriginAgent = (originAgent: any) => {
    const result: OriginAgent = {
      id: originAgent?.id,
      name: originAgent?.name,
      correspondence: originAgent?.address?.correspondence,
    };

    return result;
  };

  const formatDateToSend = (date: string | null) => {
    if (date) {
      return format(new Date(date), "yyyy-MM-dd");
    }
    return null;
  };

  const mountVoyages = (voyages: Voyage[]) => {
    const result: Voyage[] = voyages?.map((voyage) => ({
      id: voyage?.id,
      cargoLoad: formatDateToSend(voyage?.cargoLoad),
      cargoUnload: formatDateToSend(voyage?.cargoUnload),
      destination: {
        id: voyage?.destination?.id,
        name: voyage?.destination?.name,
      },
      origin: {
        id: voyage?.origin?.id,
        name: voyage?.origin?.name,
      },
      estimatedCargoLoad: formatDateToSend(voyage?.estimatedCargoLoad),
      estimatedCargoUnload: formatDateToSend(voyage?.estimatedCargoUnload),
      typeVoyage: {
        id: voyage?.typeVoyage?.id,
        name: voyage?.typeVoyage?.name,
        description: voyage?.typeVoyage?.description,
      },
      placePickup: voyage?.placePickup,
      placeDelivery: voyage?.placeDelivery,
      precedence: voyage?.precedence,
      vessel: {
        id: voyage?.vessel?.id,
        name: voyage?.vessel?.name,
      },
      voyageFlight: voyage?.voyageFlight,
      createdAt: voyage?.createdAt ?? "",
    }));

    return result;
  };

  const mountProcessData = (process: Process) => {
    const knowledges = mountKnowledges(process.knowledges);

    const master = mountMaster(knowledges);
    const house = mountHouse(knowledges);

    const processPackages = mountPackages(process.processPackages);
    const processBillLadingNcms = mountNcms(process.processBillLadingNcms);
    const processLoads = mountProcessLoads(process.processLoads);
    const processEquipments = mountEquipments(process.logisticEquipments);

    const totalOfEquipments = process?.logisticEquipments?.reduce(
      (acc, curr) => acc + curr?.quantity,
      0,
    );

    const processCharges = mountProcessCharges(process.processCharges);
    const originAgent = mountOriginAgent(process.originAgent);
    const billLadingLoads = mountBillLadingLoads(
      !!master?.directMaster ? master : house,
      totalOfEquipments,
    );

    const bookingNumber = valueOrEmptyString(process?.boarding?.bookingNumber);

    const voyages = mountVoyages(process?.boarding?.voyages);

    const result: Process = {
      knowledges: knowledges,
      masterBL: master,
      houseBL: house ? [house] : [],
      billLadingLoads: billLadingLoads,
      processPackages: processPackages,
      processBillLadingNcms: processBillLadingNcms,
      processLoads: processLoads,
      isDirectMaster: !!master?.directMaster,
      logisticEquipments: processEquipments,
      processCharges: processCharges,
      originAgent: originAgent,
      boarding: {
        bookingNumber: bookingNumber,
        voyages: voyages,
      },
      voyages: voyages,
    };

    setDefaultValues(result);

    return result;
  };

  const getProcessById = async (idProcess: number) => {
    try {
      setIsLoading(true);
      const {
        data: {process},
      } = await GetProcessById(idProcess);

      const result = mountProcessData(process);

      return result;
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const refetchProcess = async () => {
    return await getProcessById(processId);
  };

  const patchProcess = async (
    processId: number,
    changedData: Partial<Process>,
    defaultValues: Process | undefined,
    outputFormData: Process,
  ) => {
    if (Object.keys(changedData).length === 0) {
      return;
    }

    const getLogisticLoads = (loads: ProcessLoad[] | undefined) => {
      if (loads && loads.length > 0) {
        return loads;
      }
      return undefined;
    };

    const handleChangeEquipmentAndPackageContainerToIdName = (
      ladingLoads: BillLadingLoads[] | undefined | any,
    ) => {
      if (!ladingLoads) return undefined;
      const newLadingLoads = ladingLoads?.map(
        ({equipment: equip, packageContainer: pkg, ...rest}) => ({
          ...rest,
          idPackageContainer: pkg?.id,
          idEquipment: equip?.id,
        }),
      );
      return newLadingLoads;
    };

    const getLogisticKnowledges = (
      master: Knowledge | undefined,
      house: Knowledge[] | undefined,
      billLadingLoads: BillLadingLoads[] | undefined,
      isDirectMaster: boolean | undefined,
    ) => {
      const result: Knowledge[] = [];
      if (
        !changedData?.masterBL &&
        !changedData?.houseBL &&
        !changedData?.billLadingLoads
      ) {
        return undefined;
      }

      if (!master && !house && !billLadingLoads) {
        return undefined;
      }
      if (billLadingLoads?.length === 0) {
        return undefined;
      }

      if (master) {
        if (billLadingLoads && isDirectMaster) {
          result.push({
            ...master,
            logisticBillLading: {
              ...master.logisticBillLading,
              idLogisticKnowledge: master?.id,
              logisticBillLadingLoads: billLadingLoads,
            },
          });
        } else if (master.logisticBillLading?.descriptionOfPackages) {
          result.push({
            ...master,
            logisticBillLading: {
              ...master.logisticBillLading,
              idLogisticKnowledge: master.id,
            },
          });
        } else {
          result.push(master);
        }
      }

      if (house?.[0]) {
        if (billLadingLoads && !isDirectMaster) {
          result.push({
            ...house?.[0],
            logisticBillLading: {
              ...house?.[0].logisticBillLading,
              idLogisticKnowledge: house?.[0]?.id,
              logisticBillLadingLoads: billLadingLoads,
            },
          });
        } else if (house?.[0].logisticBillLading?.descriptionOfPackages) {
          result.push({
            ...house[0],
            logisticBillLading: {
              ...house[0].logisticBillLading,
              idLogisticKnowledge: house[0].id,
            },
          });
        } else {
          result.push(house[0]);
        }
      }
      return result;
    };

    const handleChangePackageToIdPackage = (packages: ProcessPackage[]) => {
      const newPackages = packages?.map(({package: pkg, ...rest}) => ({
        ...rest,
        idPackage: pkg?.id,
      }));
      return newPackages;
    };

    const getPackages = (packages: ProcessPackage[] | undefined) => {
      if (packages && packages.length > 0) {
        return handleChangePackageToIdPackage(packages);
      }

      return undefined;
    };

    const getLogisticVoyages = (voyages: Voyage[]) => {
      const voyagesToSend = voyages?.map((voyage) => ({
        id: voyage.id,
        cargoLoad: voyage?.cargoLoad,
        cargoUnload: voyage?.cargoUnload,
        idDestination: voyage?.destination?.id,
        idOrigin: voyage?.origin?.id,
        estimatedCargoLoad: voyage?.estimatedCargoLoad,
        estimatedCargoUnload: voyage?.estimatedCargoUnload,
        idTypeVoyage: voyage?.typeVoyage?.id,
        placePickup: voyage?.placePickup,
        placeDelivery: voyage?.placeDelivery,
        precedence: voyage?.precedence,
        idVessel: voyage?.vessel?.id,
        voyageFlight: voyage?.voyageFlight,
      }));

      return voyagesToSend;
    };

    const logisticBoardingsToSend = () => {
      const obj = {};
      if (changedData?.voyages) {
        obj["logisticVoyages"] = getLogisticVoyages(outputFormData.voyages);
      }

      if (changedData?.boarding?.bookingNumber) {
        obj["bookingNumber"] = outputFormData?.boarding?.bookingNumber;
      }

      if (Object.keys(obj).length > 0) {
        return obj;
      }
      return undefined;
    };

    const dataToSend = {
      logisticLoads: getLogisticLoads(changedData.processLoads),
      logisticKnowledges: getLogisticKnowledges(
        changedData?.masterBL ??
          (defaultValues?.isDirectMaster
            ? ({id: defaultValues?.masterBL?.id} as Knowledge)
            : undefined),
        changedData?.houseBL ??
          (defaultValues?.isDirectMaster
            ? undefined
            : ([{id: defaultValues?.houseBL?.[0]?.id}] as Knowledge[])),
        handleChangeEquipmentAndPackageContainerToIdName(
          changedData?.billLadingLoads,
        ),
        defaultValues?.isDirectMaster,
      ),

      logisticPackages: getPackages(changedData.processPackages),
      logisticBoardings: logisticBoardingsToSend(),
    };

    try {
      setLoading(true);
      await PatchProcessById(processId, dataToSend);
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const addNewVoyage = async (
    id: number,
    voyages: Partial<Voyage>[],
  ): Promise<Process | undefined> => {
    try {
      setLoading(true);

      const {
        data: {process},
      } = await PatchProcessById(+processId, {
        logisticBoardings: {
          logisticVoyages: [voyages],
        },
      });

      return mountProcessData(process);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProcessById(processId);
  }, []);

  return {defaultValues, isLoading, refetchProcess, patchProcess, addNewVoyage};
};

export default useProcessSectionViewData;
