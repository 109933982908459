import {AxiosResponse} from "axios";
import {PostData} from "components/ModalHCNewActivity/ModalHCNewActivity.types";
import {newBackendAPI} from "services/apiConsumer";
import {DefaultResponse} from "types/GenericTypes";
import {
  LogisticPackages,
  PostProcessEquipment,
  ProcessFile,
} from "views/ProcessDetails/Process.types";
import {
  FollowUp,
  PostFollowUpType,
} from "views/ProcessDetails/components/ProcessFollowUps/ProcessFollowUpTypes";
import {
  LogEmail,
  SearchParams,
  SentEmail,
} from "views/ProcessDetails/components/SentEmails/SentEmails.types";

export const GetProcessById = async (
  processId: number,
): Promise<AxiosResponse<any>> => {
  return await newBackendAPI.get(`/processes/${processId}`);
};

export const PatchProcessById = async (processId: number, processData: any) => {
  return await newBackendAPI.patch(`/processes/${processId}`, processData);
};

export const PostProcessFile = async (fileData: FormData) => {
  return await newBackendAPI.post(`/process-file-upload`, fileData);
};

export const PatchProcessFile = async (fileId: number) => {
  return await newBackendAPI.patch(`/process-file-upload/${fileId}`);
};

export const DeleteProcessFile = async (fileId: number) => {
  return await newBackendAPI.delete(`/delete-file/${fileId}`);
};

export const GetAllFollowUps = async (): Promise<AxiosResponse<FollowUp[]>> => {
  return await newBackendAPI.get(`/follow-ups`);
};

export const GetFollowUpById = async (
  followUpId: number,
): Promise<AxiosResponse<FollowUp>> => {
  return await newBackendAPI.get(`/follow-ups/${followUpId}`);
};

export const PostFollowUp = async (followUpData: PostFollowUpType) => {
  return await newBackendAPI.post(`/movimentation-follow-up`, followUpData);
};

export const PatchFollowUp = async (
  followUpId: number,
  followUpData: Partial<PostFollowUpType>,
) => {
  return await newBackendAPI.patch(
    `/movimentation-follow-up/${followUpId}`,
    followUpData,
  );
};

export const DownloadFileWithGuidById = async (fileId: number) => {
  return await newBackendAPI.get(`/file-download/${fileId}`);
};

export const GetIncotermsList = async (page = 1, limit = 10) => {
  return await newBackendAPI.get(`/incoterms?page=${page}&limit=${limit}`);
};

export const GetVesselsList = async (
  page = 1,
  limit = 10,
  search: string | undefined = undefined,
) => {
  const params = {page, limit, name: search};

  return await newBackendAPI.get(`/vessels`, {params});
};

export const GetTransportCompaniesList = async (
  page = 1,
  limit = 10,
  search = "",
) => {
  const params = {page, limit, name: search};
  return await newBackendAPI.get(`/transport-companies`, {
    params,
  });
};

export const GetCommoditiesList = async (page = 1, limit = 10, name = "") => {
  return await newBackendAPI.get(
    `/commodities?page=${page}&limit=${limit}&name=${name}`,
  );
};

export const GetCurrenciesList = async (page = 1, limit = 10, search = "") => {
  return await newBackendAPI.get(
    `/currencies?page=${page}&limit=${limit}&name=${search}`,
  );
};

export const GetNcms = async (page = 1, limit = 10, search = "") => {
  let code = "";
  let description = "";

  if (!Number.isNaN(Number(search))) {
    code = search;
  } else {
    description = search;
  }

  return await newBackendAPI.get(
    `/ncms?page=${page}&limit=${limit}&code=${code}&description=${description}`,
  );
};

export const LinkNcms = async (data: {
  idNcm?: number;
  idLogisticProcess?: number;
  ncms?: Array<string>;
}) => {
  return await newBackendAPI.post(`ncm-link-process`, data);
};

export const UnlinkNcms = async (idProcessBillLadingNcms: number) => {
  return await newBackendAPI.delete(
    `ncm-unlink-process/${idProcessBillLadingNcms}`,
  );
};

export const ListGroupFiles = async (page = 1, limit = 30, search?: string) => {
  const params = {
    page,
    limit,
    search,
  };
  return await newBackendAPI.get(`list-group-files`, {params});
};

export const GetLogisticAgency = async (
  page = 1,
  limit = 20,
  search: string | undefined = undefined,
) => {
  const params = {page, limit, name: search};
  return await newBackendAPI.get(`/agencies-logistics`, {params});
};

export const GetAllTypes = async (
  page = 1,
  limit = 20,
  search: string | undefined = undefined,
) => {
  const params = {page, limit, name: search};
  return await newBackendAPI.get(`/types`, {params});
};

export const GetVoyageTypes = async (): Promise<AxiosResponse<any>> => {
  return await newBackendAPI.get("/types?idGroup=10");
};

export const PostHCActivity = async (data: PostData) => {
  return await newBackendAPI.post("/send-hc-task", data);
};

export const GetEquipmentsList = async (
  page = 1,
  limit = 50,
  search?: string,
) => {
  const params = {page, limit, search};
  return await newBackendAPI.get("/equipments", {params});
};

export const GetPackagesList = async (
  page = 1,
  limit = 50,
  search?: string,
) => {
  const params = {page, limit, search};
  return await newBackendAPI.get("/packages", {params});
};

export const GetPackageContainersList = async (
  page = 1,
  limit = 50,
  search?: string,
) => {
  const params = {page, limit, name: search};
  return await newBackendAPI.get("/package-containers", {params});
};

export const PostNewLogisticLogisticPackage = async (
  data: Partial<LogisticPackages>,
) => {
  return await newBackendAPI.post("/logistic-packages", data);
};

export const DeleteLogisticPackage = async (idLogistickPackage: number) => {
  return await newBackendAPI.delete(`/logistic-packages/${idLogistickPackage}`);
};

export const DeleteLogisticVoyage = async (idVoyage: number) => {
  return await newBackendAPI.delete(`logistic-voyages/${idVoyage}`);
};

export const DeleteLogisticBillLadingLoad = async (
  idBillLadingLoad: number,
) => {
  return await newBackendAPI.delete(
    `/logistic-bill-lading-loads/${idBillLadingLoad}`,
  );
};

export const PostNewLogisticEquipment = async (data: PostProcessEquipment) => {
  return await newBackendAPI.post("/logistic-equipments", data);
};

export const DeleteLogisticEquipment = async (logisticEquipmentId: number) => {
  return await newBackendAPI.delete(
    `/logistic-equipments/${logisticEquipmentId}`,
  );
};

export const GetProcessFiles = async (
  idProcess: number,
  idGroupFile: number,
): Promise<AxiosResponse<DefaultResponse<ProcessFile>>> => {
  const params = {idGroupFile};

  return await newBackendAPI.get(`/process-files/${idProcess}`, {params});
};

export const GetSentEmails = async (
  idGroupActivity: number,
  filters: Partial<SearchParams>,
): Promise<AxiosResponse<DefaultResponse<SentEmail[]>>> => {
  const params = {idGroupActivity, ...filters};

  return await newBackendAPI.get(`/movimentation-messages`, {params});
};

export const GetLogEmailById = async (
  emailId: number,
): Promise<AxiosResponse<LogEmail>> => {
  return await newBackendAPI.get(`movimentation-messages/${emailId}`);
};
