/* eslint-disable @typescript-eslint/no-explicit-any */
import {Dispatch, SetStateAction, cloneElement} from "react";
import pxToRem from "hooks/usePxToRem";

import {
  Divider,
  Typography,
  IconButton,
  Stack,
  Box,
  ButtonProps,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {useHistory} from "react-router-dom";

import {QuickSearch} from "./components";

interface PageTitleProps {
  title: string;
  subtitle: string;
  actionButton?: React.ReactElement<ButtonProps>;
  search?: {
    function?: ((item?: any) => void) | ((item?: any) => Promise<void>);
    value: string;
    setValue: Dispatch<
      SetStateAction<{onChangeValue: string; formattedValue: string}>
    >;
    placeholder?: string;
    fnHandleClear?: () => void;
  };
  goBackFunction?: () => void;
  children?: JSX.Element | JSX.Element[];
  chip?: JSX.Element;
  dividerShadow?: JSX.Element;
}

const PageTitle = (props: PageTitleProps) => {
  const handleHistoryGoBack = () => {
    history.goBack();
  };

  const {
    title,
    subtitle,
    actionButton,
    search,
    goBackFunction = handleHistoryGoBack,
    children,
    chip,
    dividerShadow,
  } = props;

  const history = useHistory();
  return (
    <Stack
      justifyContent="center"
      sx={{position: "relative", height: pxToRem(78), width: "100%"}}
    >
      <Stack direction={"row"} alignItems="center">
        <Stack direction="row" sx={{width: search ? "50%" : "100%"}}>
          <IconButton onClick={goBackFunction} disableRipple>
            <ArrowBackIosIcon fontSize="large" color="primary" />
          </IconButton>

          <Box sx={{width: "100%"}}>
            <Typography
              variant="subtitle2"
              component="span"
              display="block"
              color="text.secondary"
              sx={{
                textTransform: "lowercase",
                "&:first-letter": {textTransform: "uppercase"},
              }}
            >
              {subtitle}
            </Typography>

            <Typography
              component="strong"
              color="primary"
              sx={{
                textTransform: "uppercase",
                fontSize: pxToRem(24),
                fontWeight: 700,
                lineHeight: 1.3,
              }}
            >
              {title}
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="flex-end"
          gap={pxToRem(8)}
          sx={{width: search || children ? "50%" : 0}}
        >
          {search && (
            <QuickSearch
              searchString={search.value}
              setSearchString={search.setValue}
              placeholder={search.placeholder}
              fnOnKeyDown={search.function}
              fnHandleClearSearch={search.fnHandleClear}
            />
          )}

          {actionButton &&
            cloneElement(actionButton, {
              size: "small",
              variant: "contained",
              sx: {
                textTransform: "capitalize",
                minHeight: pxToRem(47),
                minWidth: pxToRem(108),
              },
            })}
          {children}
        </Stack>
      </Stack>

      <Box
        sx={{
          position: "absolute",
          top: chip ? pxToRem(77 - 16) : pxToRem(77),
          left: pxToRem(-25),
          width: `calc(100% + ${pxToRem(50)})`,
        }}
      >
        {dividerShadow}

        <Divider sx={{"& .MuiDivider-wrapper": {zIndex: 6}}}>{chip}</Divider>
      </Box>
    </Stack>
  );
};

export default PageTitle;
