import {format, subMinutes} from "date-fns";

export const getDateMinusTimezone = (stringDate: string) => {
  const date = subMinutes(
    new Date(stringDate),
    -new Date().getTimezoneOffset(),
  );
  return date;
};

export const getFormattedDateFromValue = (
  value: string,
  mask = "dd-MM-yyyy",
) => {
  return value ? format(getDateMinusTimezone(value), mask) : "";
};

/*
  Motivo da função `getDateMinusTimezoneAdapted` e motivo do`-190`
  Para o funcionamento das datas, hoje subtraimos as 3horas da timezone,
  datas as diferenças das datas que temos no banco e o exibido no 
  front (sempre localizadas com gmt-3). 

  Para o datepicker do MUI-X, os valores são alterados conforme digitação
  O que acontece é que ao digitar, por exemplo `12-12-2` a data se torna:
  `12-12-0002`. Acontece que ocorreu uma alteração das timezones adotadas
  em 1913, então aconteceu uma correção de timezone de 6m35, por isso
  o menos 3 do GMT não é suficiente pra ajustar a data.
  ref.: https://pt.stackoverflow.com/questions/101351/gmt-retorna-0306-ao-inv%C3%A9s-de-0300-qual-o-motivo
  
  Ao terminar de digitar a data `12-12-2024`, o valor se corrige, mas 
  na visualização do usuário pode causar estranhamento e questionamentos.
  Por isso a correção

  */

export const getDateMinusTimezoneAdapted = (stringDate: string) => {
  const date = subMinutes(new Date(stringDate), -190);
  return date;
};

export const getAbsoluteDate = (date: Date) => {
  const day = String(date?.getDate())?.padStart(2, "0");
  const month = String(date?.getMonth() + 1)?.padStart(2, "0"); // Mês começa do zero

  /**
   * Função para formatar o ano durante a digitação, pois durante a digitação
   * o ano é exibido como 2 dígitos, e depois que termina de digitar, o ano é exibido
   * como 4 dígitos. Caso seja digitado 02 02 20, o ano será exibido como 2020. Caso
   * seja digitado 02 02 2025, o ano será exibido como 2025.
   */
  const formatYear = (year: number) => {
    if (year < 1000) {
      return year + 2000;
    }

    return year;
  };

  const year = formatYear(date?.getFullYear());

  const formattedStringDate = `${year}-${month}-${day}`;

  return formattedStringDate;
};
