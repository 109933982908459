/* eslint-disable @typescript-eslint/no-explicit-any */
import {Fragment, useEffect} from "react";
import pxToRem from "hooks/usePxToRem";
import SidebarMenuData from "components/Sidebar/SidebarMenuData";

import {useDispatch, useSelector} from "react-redux";
import {Types as openNotifyTypes} from "store/reducers/drawerNotify";
import {Types as sidebarTypes} from "store/reducers/sidebarReducer";

import {Stack, Typography, List, Divider} from "@mui/material";

import SidebarNavItem from "../SidebarNavItem";

import StyledStack from "./SidebarNavigation.styles";
import {RetrieveUserInformation} from "services/User/user.service";
import {getListChat} from "services/Chat/chat.service";
import {Chat} from "components/Sidebar/Sidebar.types";
import useToast from "hooks/useToast";
import {Types as chatTypes} from "store/reducers/chatReducer";
import useLoading from "hooks/useLoading";
import {useLocation} from "react-router";

interface SidebarNavigationProps {
  currentDropdownExpanded: string | null;
  onCurrentDropdownExpandedChange: (arg: string | null) => void;
}

const SidebarNavigation = ({
  currentDropdownExpanded,
  onCurrentDropdownExpandedChange,
}: SidebarNavigationProps) => {
  const {menuNavigationData} = SidebarMenuData();
  const dispatch = useDispatch();
  const stateChat = useSelector((state: any) => state.stateChat);
  const [showToast] = useToast();
  const [setLoading] = useLoading();
  const {
    userInfo: {
      settings: {expandedMenu},
    },
  } = useSelector((state: any) => state.stateUserInfo);

  const handleChangeCurrentDropdownExpanded = (dropdownTitle: string) => {
    if (dropdownTitle === currentDropdownExpanded)
      return onCurrentDropdownExpandedChange("");

    onCurrentDropdownExpandedChange(dropdownTitle);
  };

  const handleOpenDropdownMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    dispatch({
      type: sidebarTypes.CHANGE_ANCHOR_ELEMENT,
      currentAnchorElement: event.currentTarget,
    });
    if (currentDropdownExpanded !== "") return;

    onCurrentDropdownExpandedChange(event.currentTarget.ariaLabel);
  };

  const handleOpenNotificationDrawer = () => {
    dispatch({
      type: openNotifyTypes.OPEN_NOTIFY,
      open: true,
    });
  };

  const getNumberOfNotifications = (chats: Chat[]): number => {
    let numberOfNewChats = 0;

    chats.forEach((element) => {
      if (element.newMessages > 0) {
        numberOfNewChats++;
      }
    });

    return numberOfNewChats;
  };

  const getListOfChats = async () => {
    const user = RetrieveUserInformation();
    try {
      const response = await getListChat(user?._id);
      const newNotifications = getNumberOfNotifications(response?.data);

      if (newNotifications > 0 && newNotifications !== stateChat.newMessages) {
        showToast("Novo chat!", "warning");
      }
      dispatch({
        type: chatTypes.MY_LIST_CHAT,
        data: response.data,
        newMessages: newNotifications,
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (RetrieveUserInformation()) {
      const interval = setInterval(() => {
        getListOfChats();
      }, 300000);

      return () => clearInterval(interval);
    }
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (RetrieveUserInformation()) {
      (async function () {
        if (!location.pathname.includes("chat")) {
          setLoading(true);
          await getListOfChats();
          setLoading(false);
        }
      })();
    }
  }, []);

  const formatNumberOfNewChats = (numberOfChats: number) => {
    return numberOfChats > 0 ? numberOfChats : undefined;
  };

  return (
    <StyledStack spacing={pxToRem(20)} isSidebarOpen={expandedMenu}>
      {menuNavigationData?.map((groupItem: any, index: number, array: []) => {
        return (
          <Fragment key={groupItem.name + groupItem.id}>
            <Stack className="navigation" spacing={pxToRem(8)}>
              {expandedMenu && (
                <Typography
                  variant="subtitle2"
                  component="strong"
                  className="navigation__title"
                >
                  {groupItem.name}
                </Typography>
              )}

              <List className="navigation__list">
                {groupItem.routes?.map((navItem: any) => (
                  <SidebarNavItem
                    key={navItem.name}
                    name={navItem.name}
                    icon={navItem.icon}
                    {...(navItem.name === "Notificações"
                      ? {onClickRequest: handleOpenNotificationDrawer}
                      : {route: navItem.path})}
                    badgeText={
                      navItem.name === "Chat"
                        ? formatNumberOfNewChats(stateChat?.newMessages)
                        : undefined
                    }
                  />
                ))}
                {groupItem.subCollection?.map((subCollection: any) => (
                  <SidebarNavItem
                    key={subCollection.name}
                    name={subCollection.name}
                    icon={subCollection.icon}
                    onClickRequest={(event) =>
                      expandedMenu
                        ? handleChangeCurrentDropdownExpanded(
                            subCollection.name,
                          )
                        : handleOpenDropdownMenu(event)
                    }
                    dropdownContent={subCollection.routes}
                    currentDropdownExpanded={currentDropdownExpanded}
                    onExpandedDropdownChange={onCurrentDropdownExpandedChange}
                  />
                ))}
              </List>
            </Stack>

            {array.length - 1 !== index && <Divider />}
          </Fragment>
        );
      })}
    </StyledStack>
  );
};

export default SidebarNavigation;
