import {utcToZonedTime} from "date-fns-tz";

export const DAYS_POSSIBLE_TO_POSTPONE_ACTIVITY =
  process.env.REACT_APP_DAYS_POSSIBLE_TO_POSTPONE_ACTIVITY ?? 10;

export const defaultTablePagination = {
  initialPage: 0,
  pageSize: 20,
};

export const REACT_APP_DISABLE_VOYAGE_FUTURE_DATE: number[] = JSON.parse(
  process.env.REACT_APP_DISABLE_VOYAGE_FUTURE_DATE ?? `[]`,
);

export const timeZone = "America/Sao_Paulo";
export const brazilianTimeZoneCurrentDate = utcToZonedTime(
  new Date(),
  timeZone,
);

export const containerNumberLength = 11;

export const TYPE_VOYAGES_TO_SHOW = [
  "Coleta",
  "Transbordo / Escala",
  "Destino final",
];
