import {CsvTableDownloader} from "components";
import pxToRem from "hooks/usePxToRem";
import {
  MRT_RowData,
  MRT_TableOptions,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import {MRT_Localization_PT_BR} from "material-react-table/locales/pt-BR";

export const getDefaultMRTOptions = <TData extends MRT_RowData>(): Partial<
  MRT_TableOptions<TData>
> => ({
  muiTableBodyCellProps: () => ({
    sx: (theme) => ({
      fontSize: pxToRem(12),
      backgroundColor: theme.palette.background.paper,
    }),
  }),

  muiTableContainerProps: {
    sx: (theme) => ({
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    }),
  },
  muiTablePaperProps: {
    elevation: 0,
    sx: {
      display: "flex",
      flexDirection: "column",
    },
  },
  muiTableHeadCellProps: () => ({
    sx: (theme) => ({
      backgroundColor: theme.palette.background.paper,
      fontSize: pxToRem(13),
      fontWeight: 600,
    }),
  }),
  muiBottomToolbarProps: {
    sx: (theme) => ({backgroundColor: theme.palette.background.paper}),
  },
  muiTopToolbarProps: {
    sx: (theme) => ({
      backgroundColor: theme.palette.background.paper,
    }),
  },
  muiToolbarAlertBannerProps: {
    sx: (theme) => ({
      fontSize: ".8rem",
      backgroundColor: theme.palette.background.paper,
    }),
  },
  enableRowSelection: false,
  enableRowActions: true,
  layoutMode: "grid",
  enableColumnResizing: true,
  enableStickyHeader: true,
  enableColumnPinning: true,
  enableStickyFooter: true,
  initialState: {
    density: "compact",
    columnPinning: {
      left: ["mrt-row-actions"],
    },
    pagination: {
      pageSize: 20,
      pageIndex: 0,
    },
  },
  muiPaginationProps: {
    rowsPerPageOptions: [1, 20, 50, 100],
  },

  displayColumnDefOptions: {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        align: "center",

        sx: (theme) => ({
          boxShadow: "4px 0 8px 0 rgba(0, 0, 0, 0.1)",
          backgroundColor: theme.palette.background.paper,
        }),
      },

      muiTableBodyCellProps: () => ({
        align: "center",
        sx: (theme) => ({
          boxShadow: "4px 0 8px 0 rgba(0, 0, 0, 0.1)",
          backgroundColor: theme.palette.background.paper,
        }),
      }),
    },
  },
  muiColumnActionsButtonProps: {size: "small"},
  renderToolbarInternalActions: ({table}) => (
    <>
      <MRT_ToggleGlobalFilterButton table={table} />
      <MRT_ToggleFiltersButton table={table} />
      <CsvTableDownloader table={table} />
    </>
  ),
  localization: MRT_Localization_PT_BR,
});
