import {AxiosResponse} from "axios";
import {newBackendAPI} from "services/apiConsumer";
import {MetaData, QueryPagination} from "types/GenericTypes";
import {MessageModel} from "views/MessageModels/MessageModels.types";

type Response = {
  data: MessageModel[];
  meta: MetaData;
};
export const GetMessageModels = async (
  filter: QueryPagination & {type?: string},
): Promise<AxiosResponse<Response>> => {
  const {page, limit, ...filters} = filter;
  const params = {filter: filters, page: page, limit: limit};
  return await newBackendAPI.get(`/phrases`, {params});
};

export const GetMessageModelById = async (
  id: number,
): Promise<AxiosResponse<Response>> => {
  return await newBackendAPI.get(`/phrases/${id}`);
};

export const PostMessageModel = async (
  message: Partial<MessageModel>,
): Promise<AxiosResponse<any>> => {
  return await newBackendAPI.post(`/phrases`, message);
};

export const PatchMessageModel = async (
  id: number,
  idPerson: number,
  message: Partial<MessageModel>,
): Promise<AxiosResponse<any>> => {
  const params = {idPerson};
  return await newBackendAPI.patch(`/phrases/${id}`, message, {params});
};

export const DeleteMessageModel = async (
  id: number,
  idPerson: number,
): Promise<AxiosResponse<any>> => {
  const params = {idPerson};
  return await newBackendAPI.delete(`/phrases/${id}`, {params});
};
