import {List, ListItemButton, ListItemText, TextField} from "@mui/material";
import {Grid} from "@mui/system";
import pxToRem from "hooks/usePxToRem";
import {ReactNode} from "react";
import {extractTextFromHtmlString} from "util/extractTextFromHtmlString";
import {MessageModelFollowUpModal} from "views/ProcessDetails/components/ProcessFollowUps/components/NewFollowUpModal/useFollowUpModal";

type Props = {
  children: ReactNode[];
  items: {id: number; title: string; content: string}[];
  selectedItem: MessageModelFollowUpModal | undefined;
  searchRef: React.Ref<any> | undefined;
  handleKeyDownOnSearch: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  handleSearch: (input: string) => void;
  showSidebar: boolean;
  handleClickOnListItem: (item: MessageModelFollowUpModal) => void;
  itemRefs: React.MutableRefObject<(HTMLLIElement | null)[]>;
};

const MessageModelsList = (props: Props) => {
  const {
    children,
    items,
    selectedItem,
    searchRef,
    handleKeyDownOnSearch,
    handleSearch,
    showSidebar,
    handleClickOnListItem,
    itemRefs,
  } = props;

  return (
    <Grid container>
      <Grid size={{xs: showSidebar ? 9 : 12}}>{children}</Grid>
      {showSidebar && (
        <Grid size={{xs: 3}}>
          <Grid size={{xs: 12}} sx={{p: pxToRem(10), pt: 0}}>
            <TextField
              size="small"
              inputRef={searchRef}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              onKeyDown={handleKeyDownOnSearch}
              fullWidth
              placeholder="Buscar"
            />
          </Grid>
          <List
            sx={{width: "100%", maxHeight: "250px", overflow: "auto"}}
            dense
            disablePadding
          >
            {items.map((item, index) => (
              <ListItemButton
                key={item.id}
                selected={selectedItem?.id === item.id}
                onClick={() => handleClickOnListItem(item)}
                ref={(el: any) => (itemRefs.current[index] = el)}
              >
                <ListItemText
                  primary={item.title}
                  secondary={
                    <p>
                      {extractTextFromHtmlString(item.content).substring(
                        0,
                        100,
                      )}
                    </p>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default MessageModelsList;
