export const saveLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const readLocalStorage = <T = any>(key: string): T => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : (null as T);
};

export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
