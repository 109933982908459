import {Fragment, useState} from "react";
import {useFormContext} from "react-hook-form";
import {
  ModalAddNcm,
  NcmList,
} from "views/ProcessDetails/components/ProcessLoad/components";
import {
  DefaultProcessDetails,
  ProcessBillLadingNcm,
} from "views/ProcessDetails/Process.types";

type Props = {
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
  processId?: number;
};

const ActivityNcms = (props: Props) => {
  const {refetchProcess, processId} = props;
  const {watch} = useFormContext();

  const [isModalAddNcmOpen, setIsModalAddNcmOpen] = useState(false);

  const handleOpenModalAddNcm = () => setIsModalAddNcmOpen(true);
  const handleCloseModalAddNcm = () => setIsModalAddNcmOpen(false);

  const [ncmsTableData, setNcmsTableData] = useState<ProcessBillLadingNcm[]>(
    watch("processBillLadingNcms") ?? [],
  );

  return (
    <Fragment>
      {isModalAddNcmOpen && (
        <ModalAddNcm
          isModalOpen={isModalAddNcmOpen}
          onRequestCloseModal={handleCloseModalAddNcm}
          refetchProcess={refetchProcess}
          setNcmsTableData={setNcmsTableData}
          processId={processId}
        />
      )}
      <NcmList
        onRequestOpenModalAddNcm={handleOpenModalAddNcm}
        refetchProcess={refetchProcess}
        ncmsTableData={ncmsTableData}
        setNcmsTableData={setNcmsTableData}
      />
    </Fragment>
  );
};

export default ActivityNcms;
