/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Tooltip} from "@mui/material";
import {ActivitiesHeader, ModalCompleteActivity, PageTitle} from "components";
import {ActivityTable} from "components/Tables";
import GenericActivityData from "./GenericActivityData";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import useLoading from "hooks/useLoading";
import {Task} from "components/ModalCompleteActivity/CompleteActivity.types";
import useAlertMessage from "hooks/useAlertMessage";
import {GetTaskToFinish} from "services/api_v2/CompleteActivity/CompleteActivity.service";
import pxToRem from "hooks/usePxToRem";
import {useDispatch, useSelector} from "react-redux";
import {format} from "date-fns";
import {CustomButton} from "ui";
import ModalCompleteInBatchConfirm from "../ModalCompleteInBatchConfirm";
import {formatFieldIfDate} from "components/ModalCompleteActivity/util/formatDateField";
import TablesSearchFunction from "util/tablesSearchFunction";
import ModalProcessObservations from "../ModalProcessObservations";
import useChangeMrtReduxPagination from "hooks/useChangeMrtReduxPagination";
import {Types as activitiesTypes} from "store/reducers/activitiesReducer";
import PostPoneActivityPopper from "../PostPoneActivityPopper";
import usePostponePopper from "views/Activities/usePostponePopper";
import {getClientClassification} from "util/getClientClassification";

const GenericActivity = () => {
  const {
    tableLoading,
    tableBody,
    tableColumns,
    getActivityData,
    rowSelection,
    setRowSelection,
    activitiesToCompleteInBatch,
    setTableBody,
    openObservationsModal,
    openOrCloseObservationsModal,
    activityDataObservations,
  } = GenericActivityData({});

  const history = useHistory();
  const dispatch = useDispatch();

  const {activityName, activityId} = useParams<{
    activityName: string;
    activityId: string;
  }>();
  const {handleChangePagination} = useChangeMrtReduxPagination();

  const {definedRanges, companyName} = useSelector(
    (state: any) => state.stateDashboardFilter,
  );
  const {activitiesPagination} = useSelector(
    (state: any) => state.stateActivitiesReducer,
  );

  const [startDate, endDate] = definedRanges;
  const formattedStartDate = format(startDate, "yyyy-MM-dd");
  const formattedEndDate =
    endDate === null ? undefined : format(endDate, "yyyy-MM-dd");

  const [openCompleteActivityModal, setOpenCompleteActivityModal] =
    useState<boolean>(false);
  const [openConfirmBatchModal, setOpenConfirmBatchModal] = useState(false);

  const [currentProcessNumber, setCurrentProcessNumber] = useState<string>("");
  const [processAndGroupActivityId, setProcessAndGroupActivityId] = useState({
    processId: "",
    groupActivityId: "",
  });

  const [globalFilter, setGlobalFilter] = useState("");

  const [setLoading] = useLoading();
  const [showAlert] = useAlertMessage();

  const [taskData, setTaskData] = useState({} as Task);

  const handleFinishActivity = async (activity) => {
    setCurrentProcessNumber(activity?.groupActivity?.processes?.processNumber);
    setProcessAndGroupActivityId({
      processId: activity?.groupActivity?.processes?.id.toString(),
      groupActivityId: activity?.idGroupActivity?.toString(),
    });

    try {
      setLoading(true);

      const response = await GetTaskToFinish(+activity.id);

      setTaskData({
        ...response?.data?.activity,
        idGroupActivity: response.data.activity.idGroupActivity,
        files: [],
        clientClassification: getClientClassification(
          response?.data?.activity?.client?.classifications,
          response?.data?.activity?.process?.operation,
          response?.data?.activity?.process?.modality,
        )?.classification?.name,
        monitoredFields:
          response?.data?.activity?.monitoredFields?.map((item) => ({
            ...item,
            value: {
              id: item.fieldValue?.id ?? null,
              label: formatFieldIfDate(item) ?? "",
            },
            uiComponentChecked:
              item.tableName === "ui_component" ? false : null,
          })) ?? null,
      });

      setOpenCompleteActivityModal(true);
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getUserActivities = (
    startDate?: string | undefined,
    endDate?: string | undefined,
    _?: string | undefined,
    idClient?: number | undefined,
  ) => {
    getActivityData(activityId, startDate, endDate, idClient);
  };

  const refetchActivities = async () => {
    const [startDate, endDate] = definedRanges;
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate =
      endDate === null ? undefined : format(endDate, "yyyy-MM-dd");
    await getActivityData(
      activityId,
      formattedStartDate,
      formattedEndDate,
      companyName?.id,
    );
  };

  useEffect(() => {
    if (activitiesPagination.pageIndex !== 0) {
      dispatch({
        type: activitiesTypes.SET_ACTIVITIES_PAGINATION,
        activitiesPagination: {...activitiesPagination, pageIndex: 0},
      });
    }
  }, [globalFilter]);

  TablesSearchFunction({
    globalFilter,
    currentList: tableBody,
    setCurrentList: setTableBody,
    getFn: () =>
      getActivityData(
        activityId,
        formattedStartDate,
        formattedEndDate,
        companyName?.id,
      ),
    objKeyToCompare: "groupActivity.processes.processNumber",
    noApiSearch: true,
  });

  const handleGoBackToDashboard = () => {
    history.push("/dashboards");
  };

  const {
    activityIdToPostpone,
    anchorEl,
    handleClosePostponeActivityPopper,
    handlePostponeActivity,
    postponePopperIsOpen,
    processNumberToPostpone,
  } = usePostponePopper();

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PageTitle
        title={decodeURIComponent(activityName)}
        subtitle="Operacional"
        goBackFunction={handleGoBackToDashboard}
      />

      {openCompleteActivityModal && (
        <ModalCompleteActivity
          isOpen={openCompleteActivityModal}
          setIsOpen={setOpenCompleteActivityModal}
          processNumber={currentProcessNumber}
          processAndGroupActivityId={processAndGroupActivityId}
          firstTaskData={taskData}
          getActivityData={refetchActivities}
          activitiesToCompleteInBatch={
            tableBody[0]?.isCompletedInBatch ? activitiesToCompleteInBatch : []
          }
          setRowSelection={setRowSelection}
        />
      )}
      <ModalCompleteInBatchConfirm
        activityName={decodeURIComponent(activityName)}
        activitiesToCompleteInBatch={activitiesToCompleteInBatch}
        confirmCompleteInBatchFn={() =>
          handleFinishActivity(activitiesToCompleteInBatch[0])
        }
        isOpen={openConfirmBatchModal}
        setIsOpen={setOpenConfirmBatchModal}
      />
      <ModalProcessObservations
        activityData={activityDataObservations}
        isOpen={openObservationsModal}
        onClose={openOrCloseObservationsModal}
        getActivityData={getActivityData}
      />
      <Box sx={{p: `${pxToRem(24)} 0 ${pxToRem(24)}`}}>
        <ActivitiesHeader
          total={activitiesPagination?.totalNumberOfPages}
          getActivities={getUserActivities as () => Promise<void>}
        />
      </Box>
      <PostPoneActivityPopper
        anchorEl={anchorEl}
        postponePopperIsOpen={postponePopperIsOpen}
        processNumberToPostpone={processNumberToPostpone}
        processIdToPostpone={activityIdToPostpone}
        handleClose={handleClosePostponeActivityPopper}
        onClose={refetchActivities}
      />

      <ActivityTable
        columns={tableColumns}
        data={tableBody}
        isLoading={tableLoading}
        manualPagination
        rowCount={activitiesPagination?.totalNumberOfPages}
        tablePagination={activitiesPagination}
        onPaginationChange={(value) =>
          handleChangePagination(
            value,
            activitiesTypes.SET_ACTIVITIES_PAGINATION,
            "activitiesPagination",
            activitiesPagination,
          )
        }
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        setGlobalFilter={setGlobalFilter}
        actionButtonsData={[
          {
            iconName: "check_circle",
            onClick: (activity: any) => {
              handleFinishActivity(activity);
            },
            tooltipText: "Concluir atividade",
            disabled: activitiesToCompleteInBatch?.length > 0,
          },
          {
            iconName: "update",
            onClick: (
              activity: any,
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
            ) => {
              handlePostponeActivity(
                activity.id,
                activity?.groupActivity?.processes?.processNumber,
                e,
              );
            },
            tooltipText: "Adiar vencimento",
          },
        ]}
        actionsColumnSize={155}
        renderNewToolbarAction={
          tableBody[0]?.isCompletedInBatch ? (
            <Tooltip
              title={
                activitiesToCompleteInBatch?.length <= 1
                  ? "Selecione atividades para finalizar em lote"
                  : ""
              }
              placement="top"
              arrow
            >
              <Box>
                <CustomButton
                  disabled={activitiesToCompleteInBatch?.length <= 1}
                  size={"small"}
                  onClickFn={() => setOpenConfirmBatchModal(true)}
                  sx={{fontSize: pxToRem(11)}}
                >
                  Concluir em lote
                </CustomButton>
              </Box>
            </Tooltip>
          ) : undefined
        }
      />
    </Box>
  );
};

export default GenericActivity;
