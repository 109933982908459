import {lazy} from "react";
import PrivateLayout from "./layouts/Private";
import PublicLayout from "./layouts/Public";
import redirectToDashboardOrLogin from "util/redirectToDashboardOrLogin";

export type RoutesType = {
  path?: string;
  exact?: boolean;
  component:
    | (() => null)
    | React.LazyExoticComponent<any>
    | ((props: any) => JSX.Element);
  route?: string;
  routes?: {
    path: string;
    exact: boolean;
    component: React.LazyExoticComponent<any>;
  }[];
}[];

const routes: RoutesType = [
  {
    path: "/",
    exact: true,
    component: () => redirectToDashboardOrLogin(),
  },
  {
    path: "/online-release/send-files/:processID/:processNumber",
    component: PublicLayout,
    routes: [
      {
        path: "/online-release/send-files/:processID/:processNumber",
        exact: true,
        component: lazy(
          () =>
            import(
              "./views/Release/SendDocuments/components/ProcessDetails/components/FilesManagement/components/MobileSendDocuments"
            ),
        ),
      },
    ],
  },
  {
    path: "/send-files/:userID/:processID/:processNumber/:token",
    component: PublicLayout,
    routes: [
      {
        path: "/send-files/:userID/:processID/:processNumber/:token",
        exact: true,
        component: lazy(
          () =>
            import(
              "./views/OldOnlineRelease/components/DocumentsRelease/components/MobileFilesRelease"
            ),
        ),
      },
    ],
  },
  {
    path: "/send-hbl/:userID/:processID/:processNumber/:token",
    component: PublicLayout,
    routes: [
      {
        path: "/send-hbl/:userID/:processID/:processNumber/:token",
        exact: true,
        component: lazy(() => import("./components/MobileFilesRelease")),
      },
    ],
  },
  {
    path: "/login",
    component: PublicLayout,
    routes: [
      {
        path: "/login",
        exact: true,
        component: lazy(() => import("./views/Login")),
      },
      {
        path: "/login/forgot-password",
        exact: true,
        component: lazy(() => import("./views/Login")),
      },
      {
        path: "/login/recover-password-code",
        exact: true,
        component: lazy(() => import("./views/Login")),
      },
      {
        path: "/login/new-password",
        exact: true,
        component: lazy(() => import("./views/Login")),
      },
      {
        path: "/login/update-password",
        exact: true,
        component: lazy(() => import("./views/Login")),
      },
      {
        path: "/login/send-files/:processID/:processNumber",
        exact: true,
        component: lazy(
          () => import("./views/Release/LoginMobileSendDocuments"),
        ),
      },
    ],
  },

  {
    path: "/admin-panel",
    component: PrivateLayout,
    routes: [
      {
        path: "/admin-panel/confirm-payments",
        exact: true,
        component: lazy(() => import("./views/Panel/ConfirmPayments")),
      },
      {
        path: "/admin-panel/change-responsible",
        exact: true,
        component: lazy(() => import("./views/Panel/ChangeResponsibleProcess")),
      },
    ],
  },

  {
    path: "/activity-details/:activityId/:activityName",
    exact: true,
    component: PrivateLayout,
    routes: [
      {
        path: "/activity-details/:activityId/:activityName",
        exact: true,
        component: lazy(
          () => import("./views/Activities/components/GenericActivity"),
        ),
      },
    ],
  },

  {
    route: "*",
    component: PrivateLayout,
    routes: [
      {
        path: "/dashboards",
        exact: true,
        component: lazy(() => import("./views/Dashboards")),
      },
      {
        path: "/chat",
        exact: true,
        component: lazy(() => import("./views/Chat")),
      },
      {
        path: "/orders",
        exact: true,
        component: lazy(() => import("./views/Order")),
      },

      {
        path: "/financial",
        exact: true,
        component: lazy(() => import("./views/Financial")),
      },
      {
        path: "/companies",
        exact: true,
        component: lazy(() => import("./views/Companies")),
      },
      {
        path: "/companies-cp",
        exact: true,
        component: lazy(() => import("./views/CPManagement/CompaniesCP")),
      },
      {
        path: "/user-details",
        exact: true,
        component: lazy(() => import("./views/UserProfile")),
      },
      {
        path: "/user-details/:id",
        exact: true,
        component: lazy(() => import("./views/UserProfile")),
      },
      {
        path: "/online-release/lists",
        exact: true,
        component: lazy(() => import("./views/Release/StatusLists")),
      },
      {
        path: "/online-release/send-documents/:processId",
        exact: true,
        component: lazy(() => import("./views/Release/SendDocuments")),
      },
      {
        path: "/online-release/approve-procuration",
        exact: true,
        component: lazy(() => import("./views/Release/ApproveProcuration")),
      },
      {
        path: "/online-release-inext/lists",
        exact: true,
        component: lazy(() => import("./views/ReleaseiNext/Documents")),
      },
      {
        path: "/online-release-inext/send-documents",
        exact: true,
        component: lazy(() => import("./views/ReleaseiNext/SendDocuments")),
      },

      {
        path: "/search-processes",
        exact: true,
        component: lazy(() => import("./views/ProcessSearch")),
      },
      {
        path: "/process-details/:id",
        exact: true,
        component: lazy(
          () => import("./views/ProcessDetails/ProcessDetailsHandleData"),
        ),
      },
      {
        path: "/company-details/:id",
        exact: true,
        component: lazy(() => import("./views/Companies/CompanyDetails")),
      },
      {
        path: "/users/:companyId",
        exact: true,
        component: lazy(() => import("./views/CPManagement/UsersCP")),
      },
      {
        path: "/user-details-cp/:companyId/:userId",
        exact: true,
        component: lazy(() => import("./views/CPManagement/UserProfileCP")),
      },
      {
        path: "/branches/:companyId",
        exact: true,
        component: lazy(() => import("./views/CPManagement/BranchesCP")),
      },
      {
        path: "/brokers/:companyId",
        exact: true,
        component: lazy(() => import("./views/CPManagement/BrokersCP")),
      },

      {
        path: "/reports",
        exact: true,
        component: lazy(() => import("./views/Reports")),
      },
      {
        path: "/users-overview",
        exact: true,
        component: lazy(() => import("./views/UsersOverview")),
      },
      {
        path: "/pending-hbl",
        exact: true,
        component: lazy(() => import("./views/Release/PendingHbl")),
      },
      {
        path: "/received-courier",
        exact: true,
        component: lazy(
          () => import("./views/Courier/components/ReceivedCourier"),
        ),
      },
      {
        path: "/add-courier",
        exact: true,
        component: lazy(() => import("./views/Courier/components/AddCourier")),
      },
      {
        path: "/monitor-courier",
        exact: true,
        component: lazy(
          () => import("./views/Courier/components/MonitorCourier"),
        ),
      },
      {
        path: "/deconsolidation",
        exact: true,
        component: lazy(() => import("./views/Deconsolidation")),
      },
      {
        path: "/contracts",
        exact: true,
        component: lazy(() => import("./views/Contracts/ContractsList")),
      },
      {
        path: "/contract-details/:id",
        exact: true,
        component: lazy(() => import("./views/Contracts/ContractDetails")),
      },
      {
        path: "/dev/configs",
        exact: true,
        component: lazy(() => import("./views/DevConfigs")),
      },
      {
        path: "/access-denied",
        exact: true,
        component: lazy(() => import("./views/AccessError/AccessDenied")),
      },
      {
        path: "/page-not-found",
        exact: true,
        component: lazy(() => import("./views/AccessError/PageNotFound")),
      },
      {
        path: "/tasks/:id/:projectTaskName",
        exact: true,
        component: lazy(() => import("./views/Tasks")),
      },
      {
        path: "/project-tasks",
        exact: true,
        component: lazy(() => import("./views/Tasks")),
      },
      {
        path: "/task-details/:idTaskGroup/:idTask",
        exact: true,
        component: lazy(() => import("./views/Tasks")),
      },
      {
        path: "/follow-up-templates/list",
        exact: true,
        component: lazy(() => import("./views/FollowUpTemplates")),
      },
      {
        path: "/follow-up-templates/details/:idTemplate",
        exact: true,
        component: lazy(() => import("./views/FollowUpTemplates")),
      },
      {
        path: "/email-templates/list",
        exact: true,
        component: lazy(() => import("./views/EmailTemplates")),
      },
      {
        path: "/email-templates/details/:idTemplate",
        exact: true,
        component: lazy(() => import("./views/EmailTemplates")),
      },
      {
        path: "/divergent-processes",
        exact: true,
        component: lazy(() => import("./views/DeviantProcesses")),
      },
      {
        path: "/divergent-processes/:id",
        exact: true,
        component: lazy(
          () => import("./views/DeviantProcesses/MerchantIntegration"),
        ),
      },
      {
        path: "/fields-monitoring",
        exact: true,
        component: lazy(() => import("./views/FieldsMonitoring")),
      },
      {
        path: "/fields-monitoring/details/:id",
        exact: true,
        component: lazy(
          () =>
            import(
              "./views/FieldsMonitoring/components/CreateFieldsMonitoring"
            ),
        ),
      },
      {
        path: "/activities-history",
        exact: true,
        component: lazy(() => import("./views/ActivitiesHistory")),
      },
      {
        path: "/pending-emails",
        exact: true,
        component: lazy(() => import("./views/PendingEmails")),
      },
      {
        path: "/activities-monitored-fields",
        exact: true,
        component: lazy(() => import("./views/ActivitiesMonitoredFields")),
      },
      {
        path: "/nexperts",
        exact: true,
        component: lazy(() => import("./views/Nexperts/NexpertsList")),
      },
      {
        path: "/nexperts/:id",
        exact: true,
        component: lazy(() => import("./views/Nexperts/NexpertDetails")),
      },
      {
        path: "/message-models",
        exact: true,
        component: lazy(() => import("./views/MessageModels")),
      },
      {
        path: "/responsibles-work-amount",
        exact: true,
        component: lazy(() => import("./views/ActivitiesReports")),
      },
      {
        path: "/responsibles-work-amount/:idResponsible",
        exact: true,
        component: lazy(() => import("./views/ActivitiesReports")),
      },
      {
        path: "/dashboard-detailed-list",
        exact: true,
        component: lazy(() => import("./views/DashboardDetailedList")),
      },
    ],
  },
];

export default routes;
