import {newBackendAPI} from "services/apiConsumer";
import {QueryPagination} from "types/GenericTypes";
import {NexpertUpdateData} from "views/Nexperts/NexpertDetails/NexpertDetails.types";

export enum PersonTypes {
  "cargoAgent" = "cargoAgent",
  "agenciesLogistic" = "agenciesLogistic",
  "client" = "client",
  "containerTerminal" = "containerTerminal",
  "customBroker" = "customBroker",
  "employer" = "employer",
  "internationalAssociation" = "internationalAssociation",
  "representative" = "representative",
  "transportCompany" = "transportCompany",
  "insurer" = "insurer",
}

type EmployeesFilter = {
  name?: string;
  positionName?: string;
  sectorName?: string;
  employerName?: string;
  email?: string;
  orderBy?: {column: string; direction: "asc" | "desc"};
};

export const GetPersonsByTypes = async (
  page = 1,
  limit = 50,
  name = "",
  filters: Array<PersonTypes>,
) => {
  return await newBackendAPI.get(
    `/persons-by-type?page=${page}&limit=${limit}&name=${name}&filters=${JSON.stringify(
      filters ?? [],
    )}`,
  );
};

export const GetEmployees = async (
  params: QueryPagination & {filter?: EmployeesFilter},
) => {
  return await newBackendAPI.get(`/employees`, {params});
};

export const GetEmployeeById = async (employeeId: number) => {
  return await newBackendAPI.get(`/employees/${employeeId}`);
};

export const UpdateEmployeeById = async (
  employeeId: number,
  data: NexpertUpdateData,
) => {
  return await newBackendAPI.patch(`/employees/${employeeId}`, data);
};

export const GetSectorsAndPositions = async (
  params: QueryPagination & {name?: string; idGroup?: number},
) => {
  return await newBackendAPI.get("/types", {params});
};
